<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="flightRouteModal"
      v-model="showModal"
      title="Edit Flight Route Details"
      ok-title="Submit"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-secondary"
      centered
      @ok="handleOk"
      @cancel="handleClose"
      @close="handleClose"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="airline name"
                rules="required"
              >
                <b-form-group
                  label="Airline name"
                  label-for="mc-select-title"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="flightRouteEditForm.airline_name"
                    :options="airlines"
                    class="airline_name"
                    placeholder="Select airline name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required|max:40"
                name="flight number"
              >
                <b-form-group label="Flight number">
                  <b-form-input
                    v-model="flightRouteEditForm.flight_no"
                    name="flight_number"
                    placeholder="VY8460"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="departure date"
                rules="required"
              >
                <b-form-group label="Departure date">
                  <!-- <flat-pickr
                    v-model="flightRouteEditForm.departure_date"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  /> -->
                  <date-picker
                    v-model="flightRouteEditForm.departure_date"
                    style="width: 100%"
                    type="datetime"
                    format="YYYY-MM-DD HH:mm"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="arrival date"
                rules="required"
              >
                <b-form-group label="Arrival date">
                  <!-- <flat-pickr
                    v-model="flightRouteEditForm.arrival_date"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  /> -->
                  <date-picker
                    v-model="flightRouteEditForm.arrival_date"
                    style="width: 100%"
                    type="datetime"
                    format="YYYY-MM-DD HH:mm"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="origin"
                rules="required"
              >
                <b-form-group
                  label="Origin"
                  label-for="mc-origin"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="flightRouteEditForm.origin"
                    :options="origins"
                    class="origin"
                    placeholder="Origin"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="destination"
                rules="required"
              >
                <b-form-group
                  label="Destination"
                  label-for="mc-destination"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="flightRouteEditForm.destination"
                    :options="destinations"
                    class="destination"
                    placeholder="Destination"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required|max:30"
                name="supplier"
              >
                <b-form-group label="Supplier">
                  <b-form-input
                    v-model="flightRouteEditForm.supplier"
                    name="supplier"
                    placeholder="RYR"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required|max:30"
                name="supplier"
              >
                <b-form-group label="Duration">
                  <!-- <b-form-input
                    v-model="flightRouteEditForm.duration"
                    name="duration"
                    placeholder="02:00"
                  /> -->
                  <vue-timepicker v-model="flightRouteEditForm.duration" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BForm,
  VBModal,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import VueTimepicker from "vue2-timepicker";
import { required } from "@/@core/utils/validations/validations";
import editBookingService from "@/services/booking/editBooking.service";
import { resConditionCheck } from "@/helpers/constant";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    VBModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    vSelect,
    flatPickr,
    VueTimepicker,
  },

  props: {
    toggleRouteModal: {
      type: Boolean,
      default: false,
    },

    flightRouteObject: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      required,
      airlines: [],
      origins: [],
      destinations: [],
      showModal: this.toggleRouteModal,
      flightRouteEditForm: {
        airline_name: {
          label: this.flightRouteObject.airline_name,
          value: this.flightRouteObject.airline_code,
        },
        airline_code: this.flightRouteObject.airline_code || "",
        flight_no: this.flightRouteObject.flight_number || "",
        departure_date:
          moment(this.flightRouteObject.departure_date).toDate() || "",
        arrival_date:
          moment(this.flightRouteObject.arrival_date).toDate() || "",
        origin: {
          label: this.flightRouteObject.departure_code,
          value: this.flightRouteObject.departure_code,
        },

        destination: {
          label: this.flightRouteObject.arrival_code,
          value: this.flightRouteObject.arrival_code,
        },

        supplier: this.flightRouteObject.supplier,
        duration: this.flightRouteObject.duration,
      },
    };
  },

  created() {
    this.getOriginListing();
    this.getAirlines();
  },

  methods: {
    handleOk() {
      this.$emit("on-submit", this.flightRouteEditForm);
    },
    handleClose() {
      this.$emit("on-cancel");
    },

    async getOriginListing() {
      try {
        const res = await editBookingService.getOriginListing(
          this.$route.query?.wl_code
        );
        if (resConditionCheck(res.status) && res.data.data) {
          this.origins = res.data.data.airports.map((e) => ({
            label: e.code,
            value: e.code,
            type: e.type,
          }));
          this.destinations = res.data.data.airports.map((e) => ({
            label: e.code,
            value: e.code,
            type: e.type,
          }));
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    async getAirlines() {
      try {
        const res = await editBookingService.getAirlines(
          this.$route.query?.wl_code
        );
        if (resConditionCheck(res.status) && res.data.data) {
          this.airlines = res.data.data.map((e) => ({
            label: e.name,
            value: e.code,
          }));
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then((success) => {
        valid = !!success;
      });
      return valid;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
