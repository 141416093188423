<template>
  <div>
    <flight-modal
      v-if="toggleModal"
      ref="flightModal"
      :flight-object="flightObject"
      :toggle-modal="toggleModal"
      @on-cancel="onCancel"
      @on-submit="onSubmit"
    />

    <flight-route-modal
      v-if="toggleRouteModal"
      ref="flightRouteModal"
      :flight-route-object="flightRouteObject"
      :toggle-route-modal="toggleRouteModal"
      @on-cancel="onModalCancel"
      @on-submit="onModalSubmit"
    />
    <!-- <feather-icon
      icon="EditIcon"
      size="21"
      style="cursor: pointer; position: absolute; right: 100px"
      @click="openModal"
    /> -->

    <div
      v-for="(route, index) in flightDetails.moduleInfo.routes"
      :key="index"
      class="mt-3"
    >
      <div class="d-flex justify-content-between mb-2">
        <h5 class="mr-2">Flight Type: {{ route.type }}</h5>
        <feather-icon
          v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1"
          icon="EditIcon"
          size="21"
          style="cursor: pointer"
          @click="openModal"
        />
      </div>
      <!-- <h5>Flight Type: {{ route.type }}</h5> -->
      <b-table
        ref="refUserListTable"
        :items="flightDetails.moduleInfo.routes[index].stops"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(airline_name)="data">
          <div style="width: 150px">
            <img style="width: 23%" :src="data.item.airline_image" alt="" />
            {{ data.item.airline_name }}
          </div>
        </template>

        <template #cell(flight_no)="data">
          {{ data.item.flight_number }}
        </template>

        <template #cell(departure-arrival)="data">
          {{ data.item.departure_code }} - {{ data.item.arrival_code }}
        </template>

        <template #cell(duration)="data">
          {{ data.item.duration }}
        </template>

        <template #cell(status)>
          {{ BookingStatus(flightDetails.bookingStatus) }}
        </template>
        <template #cell(supplier_id)>
          {{
            flightDetails.bookingReference
              ? flightDetails.bookingReference
              : "-"
          }}
        </template>

        <template #cell(id)>
          <div style="width: 160px">
            {{ flightDetails.id }}
          </div>
        </template>

        <template #cell(departure_date)="data">
          <div style="width: 100px">
            {{ moment(data.item.departure_date).format("YYYY-MM-DD") }}
            {{ moment(data.item.departure_date).format("hh:mm:ss a") }}
          </div>
        </template>

        <template #cell(arrival_date)="data">
          <div style="width: 100px">
            {{ moment(data.item.arrival_date).format("YYYY-MM-DD") }}
            {{ moment(data.item.arrival_date).format("hh:mm:ss a") }}
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="openRouteModal(data.item, route.type, data.index)"
              :disabled="!((checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable, BDropdown, BDropdownItem, BButton } from "bootstrap-vue";
import * as moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BookingStatus, resConditionCheck, RoleEnum, } from "@/helpers/constant";
import FlightModal from "@/views/modal/FlightEditModal.vue";
import editBookingService from "@/services/booking/editBooking.service";
import errorResponseHandler from "@/services/errorHandler";
import flightRouteModal from "@/views/modal/FlightRouteModal.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';

export default {
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    FlightModal,
    BButton,
    flightRouteModal,
  },

  props: {
    flightDetails: {
      type: Object,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      index: "",
      toggleModal: false,
      origins: [],
      toggleRouteModal: false,
      flightObject: {},
      flightRouteObject: {},
      flightType: "",
      moment,
      BookingStatus,
      tableColumns: [
        { key: "id" },
        { key: "airline_name" },
        { key: "flight_no" },
        { key: "departure-arrival" },
        { key: "departure_date" },
        { key: "arrival_date" },
        { key: "supplier" },
        { key: "supplier_id" },
        { key: "duration" },
        { key: "status" },
        { key: "actions" },
      ],
      RoleEnum,
			checkLoginRole,
			checkAccess: {},
      wl_code: axios.defaults.headers.wl_code,
    };
  },
  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
  },

  methods: {
    async openModal() {
      this.toggleModal = true;
      this.flightObject = this.flightDetails;
    },

    async openRouteModal(data, flightType, index) {
      this.flightType = flightType;
      this.toggleRouteModal = true;
      (this.flightRouteObject = data), (this.index = index);
    },

    onCancel() {
      this.toggleModal = false;
    },
    onModalCancel() {
      this.toggleRouteModal = false;
    },

    async onSubmit(flightForm) {
      const valid = await this.$refs.flightModal.validationForm();

      this.toggleModal = false;

      if (valid) {
        try {
          const res = await editBookingService.editFlightBooking(
            this.flightDetails.id,
            flightForm,
            this.$route.query?.wl_code
          );
          if (resConditionCheck(res.status) && res.data.message) {
            this.$emit("callBookingDetailbyId");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        } catch (error) {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },

    async onModalSubmit(flightRouteEditForm) {
      const valid = await this.$refs.flightRouteModal.validationForm();

      let modifiedArrival = moment(flightRouteEditForm.arrival_date).format("YYYY-MM-DDTHH:mm:ss");
      let modifiedDeparture = moment(flightRouteEditForm.departure_date).format("YYYY-MM-DDTHH:mm:ss");

      let cloneFlightRouteForm = JSON.parse(
        JSON.stringify(flightRouteEditForm)
      );

      console.log("modiefieddddd arr -> ", modifiedArrival, " dep -> ", modifiedDeparture)

      const payload = {
        booking_id: this.flightDetails.id,
        flight_type: this.flightType,
        airline_name: cloneFlightRouteForm.airline_name.label,
        airline_code: flightRouteEditForm.airline_name.value,
        departure_code: cloneFlightRouteForm.origin.value,
        departure_type: cloneFlightRouteForm.origin.type,
        arrival_code: cloneFlightRouteForm.destination.value,
        arrival_type: cloneFlightRouteForm.destination.type,
        flight_no: cloneFlightRouteForm.flight_no,
        departure_date: modifiedDeparture,
        arrival_date: modifiedArrival,
        supplier: cloneFlightRouteForm.supplier,
        index: this.index,
        duration: cloneFlightRouteForm.duration,
      };
      if (valid) {
        try {
          const res = await editBookingService.editFlightRoutes(payload, this.$route.query?.wl_code);
          if (resConditionCheck(res.status) && res.data.message) {
            this.$emit("callBookingDetailbyId");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        } catch (error) {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
      this.toggleRouteModal = false;
    },
  },
};
</script>
