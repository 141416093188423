<template>
  <b-overlay
    variant="white"
    :show="!isLoaded"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <confirm-delete
      ref="sendEmailModal"
      :msg="sendEmailMsg"
      :title="titleMsg"
      @on-submit="sendCustomerBookingEmail"
    />
    <div>
      <b-card>
        <b-row>
          <b-col class="py-1" md="8" sm="6">
            <h1>
              {{
                bookingDetails.header_details
                  ? bookingDetails.header_details.bookings[0].localBookingId
                  : "N/A"
              }}
            </h1>
          </b-col>  
          <b-col class="py-1 m-0" md="4" sm="3">  	
            <button v-b-modal.modal-send-email class="btn btn-primary mb-1 float-right">	
              Send Email	
            </button>	
            <b-button class="mr-1 float-right" :to="{name: 'booking-list', query: { page: $route.query.page, rows: $route.query.rows, wl_code: $route.query.wl_code }}">	
              Back	
            </b-button>	
          </b-col>

                    <b-col class="pb-1" lg="3" md="4">
                        <p class="mb-0">
                            <b>Type:</b>
                        </p>
                        <div class="custom_input_area">
                            {{
                                TripType(
                                    bookingDetails.header_details
                                        ? bookingDetails.header_details
                                              .bookings[0].moduleId
                                        : "N/A"
                                )
                            }}
                        </div>
                    </b-col>
                    <b-col class="pb-1" lg="3" md="4">
                        <p class="mb-0">
                            <b>Booking Date & Time:</b>
                        </p>
                        <div class="custom_input_area">
                            {{
                                bookingDetails.header_details
                                    ? moment(
                                          bookingDetails.header_details
                                              .bookings[0].bookingDateTime
                                      ).format("YYYY-MM-DD")
                                    : "N/A"
                            }}
                            {{ 
                                bookingDetails.header_details
                                    ? moment.utc(
                                          bookingDetails.header_details
                                              .bookings[0].bookingDateTime
                                      ).format("hh:mm:ss a")
                                    : ''
                            }}
                        </div>
                    </b-col>
                    <b-col class="pb-1" lg="3" md="4">
                        <p class="mb-0">
                            <b>Supplier ID:</b>
                        </p>
                        <div class="custom_input_area">
                            {{ supplierBookingId ? supplierBookingId : 'N/A' }}
                        </div>
                    </b-col>
                    <b-col class="pb-1" lg="3" md="4">
                        <p class="mb-0">
                            <b>Total Price:</b>
                        </p>
                        <div class="custom_input_area">
                            {{ totalAmount }}
                        </div>
                    </b-col>
                    <b-col class="pb-1" lg="3" md="4">
                        <p class="mb-0">
                            <b>Pending Payment:</b>
                        </p>
                        <div class="custom_input_area">
                            {{
                                bookingDetails.header_details && bookingDetails.header_details.pending
                                    ? bookingDetails.header_details.pending
                                    : "N/A"
                            }}
                        </div>
                    </b-col>
                    <!-- <b-col class="pb-1" lg="3" md="4">
                        <p class="mb-0">
                            <b>Cancellation Reason:</b>
                        </p>
                        <div class="custom_input_area">
                            N/A
                        </div>
                    </b-col> -->
                    <b-col class="pb-1" lg="3" md="4">
                        <p class="mb-0">
                            <b>Agent:</b>
                        </p>
                        <div class="custom_input_area">
                            {{ agentName ? agentName : 'N/A' }}
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <b-alert variant="danger" />

        <b-tabs pills>
            <!-- Tab: Account -->
            <b-tab active>
                <template #title>
                    <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Contact Details</span>
                </template>
                <!-- <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        /> -->
                <contact-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :contact-details="contactDetails"
                    :passenger-details="passengerDetails"
                    :booking-type="bookingDetails.header_details.bookings[0].moduleId"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Passengers</span>
                </template>
                <passenger-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :passenger-details="passengerDetails"
                    :flight-details="flightDetails"
                    :baggage-details="baggageDetails"
                    :booking-type="bookingDetails.header_details.bookings[0].moduleId"
                    @callBookingDetailbyId="callBookingDetailbyId"
                />
            </b-tab>

            <!-- Tab: Hotel -->
            <b-tab v-if="hotelDetails">
                <template #title>
                    <feather-icon
                        icon="HomeIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Hotel</span>
                </template>
                <hotel-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :hotel-details="hotelDetails"
                    @callBookingDetailbyId="callBookingDetailbyId"
                />
            </b-tab>

            <!-- Flight -->

            <b-tab v-if="flightDetails">
                <template #title>
                    <feather-icon
                        icon="NavigationIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Flight</span>
                </template>
                <flight-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :flight-details="flightDetails"
                    @callBookingDetailbyId="callBookingDetailbyId"
                />
            </b-tab>

            <!-- Tab: Insurance -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Extras</span>
                </template>
                <!-- <insurance-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :insurance-details="insuranceDetails"
        /> -->
                <extras
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :insurance-details="insuranceDetails"
                    :priority-details="priorityDetails"
                    :transferDetails="transferDetails"
                    :booking-type="bookingDetails.header_details.bookings[0].moduleId"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Financial</span>
                </template>
                <financial-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :financial-details="financialDetails"
                    :transfer-details="transferDetails"
                    :priority-details="priorityDetails"
                    :baggage-details="baggageDetails"
                    :booking-type="bookingDetails.header_details.bookings[0].moduleId"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Payments</span>
                </template>
                <payment-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :payment-details="paymentDetails"
                    @callBookingDetailbyId="callBookingDetailbyId"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Comments</span>
                </template>
                <comment-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :comment-details="commentDetails"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Logs</span>
                </template>
                <logs-details
                    v-if="isLoaded"
                    :is-loaded="isLoaded"
                    :logs-details="logsDetails"
                />
            </b-tab>

            <!-- <b-tab>
        <template #title>
  <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" /></template>
          <span class="d-none d-sm-inline">Logs</span>
        </template>
        <logs-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :logs-details="logsDetails"
        />
      </b-tab> -->
    </b-tabs>
    <b-modal
      id="modal-send-email"
      title="Send Email"
      cancel-title="Close"
      ok-title="Send"
      size="md"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="sendCustomerBookingEmail"
    >
      <b-overlay
        variant="white"
        :show="!isLoaded"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <validation-observer ref="emailFormRules">
          <b-form
            ref="emailForm"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent
          >
            <b-row>
              <b-col>
                <h4 for="emails">To:</h4>
              </b-col>
              <b-col>
                <b-button
                  :style="`cursor:${sendEmailForm.emails.length > 9 ? 'not-allowed': 'pointer'}`"
                  class="float-right p-0"
                  variant="success"
                  @click="repeateAgain"
                  :disabled="sendEmailForm.emails.length > 9"
                >
                  <feather-icon icon="PlusIcon" size="22"/>
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) in sendEmailForm.emails"
              :id="item.id"
              :key="item.id"
              ref="emailRow"
            >
              <b-col class="mr-0" cols="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="`Email ${index+1}`"
                    rules="required|email"
                  >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                    <b-form-input
                      v-model="sendEmailForm.emails[index].email"
                      :class="`email${index}`"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Email"
                    />
                      <!-- @input="checkDuplicate(index, $event)" -->
                    <b-input-group-append is-text>
                      <feather-icon
                        class="emailRemove"
                        icon="XIcon"
                        size="22"
                        @click="removeItem(index)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 for="emails">Message:</h4>
              </b-col>
              <b-col cols="12">
                <validation-provider
                    #default="{ errors }"
                    name="Message"
                    rules="max:250|min:3"
                  >
                  <b-form-group>
                    <quill-editor
                        id="message"
                        v-model="sendEmailForm.message"
                        class="message"
                        :options="snowOption"
                        :preserve-whitespace="false"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Message"
                      />
                    <!-- <b-form-textarea
                      id="textarea-default"
                      v-model="sendEmailForm.message"
                      placeholder="Message"
                      rows="3"
                    /> -->
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="11">
                <div class="helper" />
                <label class="btn btn-primary">
                  Attachment
                  <input
                    type="file"
                    accept="*"
                    name="doc"
                    @change="onChange"
                  >
                </label>
                {{filePreview}}
              </b-col>
            <!-- </b-row>
            <b-row> -->
              <b-col cols="1" class="d-flex align-items-right justify-content-right mb-2 mb-md-1">
                <b-row v-if="filePreview">
                  <button @click="removeFile">
                    X
                  </button>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BTab, BTabs, BAlert, BRow, BCol, BCard, BButton, VBModal, BFormGroup, BFormInput, BForm, BInputGroupAppend, BInputGroup, BFormTextarea, BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as moment from "moment";
import bookingService from "@/services/booking/booking.service";
import CustomerAdminService from "@/services/customer-admin/customerAdmin.service";
import { resConditionCheck, TripType } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ContactDetails from "./ContactDetails.vue";
import HotelDetails from "./HotelDetails.vue";
import InsuranceDetails from "./InsuranceDetails.vue";
import PaymentDetails from "./PaymentDetails.vue";
import PassengerDetails from "./PassengerDetails.vue";
import FinancialDetails from "./FinancialDetails.vue";
import FlightDetails from "./FlightDetails.vue";
import LogsDetails from "./LogsDetails.vue";
import CommentDetails from "./CommentDetails.vue";
import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";
import Ripple from "vue-ripple-directive";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { heightTransition } from '@core/mixins/ui/transition';
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Extras from "./Extras.vue";

export default {
    components: {
        BTab,
        BTabs,
        BAlert,
        BRow,
        BCol,
        BCard,
        BButton,
        BFormGroup,
        BFormInput,
        BForm,
        BInputGroupAppend,
        BInputGroup,
        BFormTextarea,
        ContactDetails,
        HotelDetails,
        InsuranceDetails,
        PaymentDetails,
        PassengerDetails,
        FinancialDetails,
        FlightDetails,
        CommentDetails,
        LogsDetails,
        ConfirmDelete,
        ValidationObserver,
        ValidationProvider,
        BOverlay,
        quillEditor,
        Extras,
    },

    data() {
        return {
            bookingDetails: {},
            contactDetails: {},
            isLoaded: false,
            hotelDetails: {},
            flightDetails: {},
            insuranceDetails: {},
            financialDetails: {},
            paymentDetails: [],
            commentDetails: {},
            passengerDetails: [],
            logsDetails: {},
            baggageDetails: {},
            priorityDetails: {},
            transferDetails: [],
            TripType,
            moment,
            totalAmount: "",
            supplierBookingId: "",
            sendEmailMsg:
                "Are you sure you want to send booking email to customer ?",
            titleMsg: "Send Email Confirmation",
            sendEmailForm: {
              emails: [{ id: 1, email: '' }],
              message: '',
              file: ''
            },
            nextEmailId: 1,
            snowOption: {
              modules: {
                toolbar: [
                  ["bold", "italic", "underline"]
                ]
              }
            },
            isError: false,
            filePreview: '',
            agentName: ''
        };
    },

    directives: {
      "b-modal": VBModal,
      Ripple
    },
    
    mixins: [heightTransition],

    async mounted() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if(bvEvent.trigger == 'headerclose' || bvEvent.trigger == 'cancel') {
          this.resetSendEmailForm();
        }
      })
      this.initTrHeight()
      await this.getBookingDetailsById(this.$route.params.id);
      this.calculateTotalAmount();
      if(this.hotelDetails ? this.hotelDetails.customerType === 2 : this.flightDetails.customerType === 2) {
        this.getAgentName();
      }
    },

    created() {
      window.addEventListener('resize', this.initTrHeight)
    },

    destroyed() {
      window.removeEventListener('resize', this.initTrHeight)
    },


    methods: {
        async getBookingDetailsById(id) { 
            try {
                const res = await bookingService.getBookingDetailsById(id, this.$route.query?.wl_code);
                if (resConditionCheck(res.status) && res.data) {
                    this.bookingDetails = res.data;
                    console.log("booking details", res.data);
                    this.isLoaded = true;
                    this.contactDetails = res.data.contact_details;
                    this.paymentDetails = res.data.payments && res.data.payments.length ? res.data.payments.map(e => ({
                      moduleId: this.bookingDetails.header_details.bookings[0].moduleId,
                      ...e,
                      paymentDate: moment(e.paymentDate).toDate()
                    })) : [];
                    this.hotelDetails = res.data.hotel;
                    this.commentDetails = res.data.comments;
                    this.passengerDetails = res.data.passengers;
                    this.financialDetails = res.data.financials;
                    this.insuranceDetails = res.data.insurance;
                    const logsDetails = res.data?.logs;
                    this.logsDetails = logsDetails.map((e) => ({
                        ...e,
                        show: false,
                    }));
                    this.flightDetails = res.data.flight ? res.data.flight : "";
                    const {
                        email,
                        paymentVia,
                    } = res.data.header_details.bookings[0];
                    this.contactDetails.email = email;
                    this.sendEmailForm.emails[0].email = email;
                    // eslint-disable-next-line no-return-assign
                    this.paymentDetails.map((e) => (e.paymentVia = paymentVia));

                    const pendingPayment = this.bookingDetails.header_details.bookings[0].moduleId !== 2 
                      ? this.bookingDetails.header_details.bookings[0].bookingInstallments.filter( (x) => x.paymentStatus !== 1) 
                      : this.bookingDetails.header_details.bookings[0].bookingInstallments[0].paymentStatus !== 1
                        ? this.bookingDetails.header_details.bookings[0].bookingInstallments[0].amount 
                        : 0;
                    this.bookingDetails.header_details.pending = this.bookingDetails.header_details.bookings[0].moduleId !== 2 ? pendingPayment.reduce(
                        (a, curr) => a + Number(curr.amount),
                        0
                    ) : pendingPayment;

                    this.supplierBookingId = this.bookingDetails?.header_details
                        ?.bookings[0].supplierBookingId
                        ? this.bookingDetails.header_details.bookings[0]
                              .supplierBookingId
                        : "N/A";
                    this.baggageDetails = res.data.baggageBoarding ? res.data?.baggageBoarding?.find(
                        (e) => e.comment === "20kg"
                    ) : {};
                    this.priorityDetails = res.data.baggageBoarding ?  res.data?.baggageBoarding?.find(
                        (e) => e.comment === "Priority"
                    ): {}
                    this.transferDetails = res.data.transfers ? res.data?.transfers : []
                }
            } catch (error) {
              console.error("Error ---------->", error)
                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },
        async callBookingDetailbyId() {
            await this.getBookingDetailsById(this.$route.params.id);
        },

        calculateTotalAmount() {
            let extrasAmount = 0;
            const totalAmount = this.bookingDetails.header_details.bookings[0].moduleId !== 2 ? this.bookingDetails.flight
                ? Number(this.bookingDetails.hotel.sellingPrice) +
                  Number(this.bookingDetails.flight.moduleInfo.flight_price)
                : Number(this.bookingDetails.hotel.sellingPrice) : Number(this.bookingDetails.flight.sellingPrice);

            if (
                this.bookingDetails.insurance ||
                this.bookingDetails.insurance?.length
            ) {
                extrasAmount = this.bookingDetails.insurance.reduce(
                    (curr, e) => {
                        let current = curr;
                        current += Number(e.amount);
                        return current;
                    },
                    0
                );
            }

            this.totalAmount = totalAmount + extrasAmount;
        },

    async sendCustomerBookingEmail(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.emailFormRules.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoaded = false;

            const formdata = new FormData();
            formdata.append("send_email", true)
            formdata.append("emails", (this.sendEmailForm.emails && this.sendEmailForm.emails.length) ? this.sendEmailForm.emails.map((e) => e.email) : [])
            formdata.append("message", this.sendEmailForm.message)
            formdata.append("file", this.sendEmailForm.file)

            const res = await bookingService.sendBookingEmail(this.$route.params.id, formdata, this.$route.query?.wl_code);
            if (resConditionCheck(res.status) && res.data) {
              this.isLoaded = true;
              this.resetSendEmailForm();
              this.$nextTick(() => {
                  this.$emit("on-submit");
                  this.$bvModal.hide("modal-send-email");
                });
              this.filePreview = "";
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Booking email sent successfully.',
                  icon: "X-CircleIcon",
                  variant: "success"
                }
              });
            }
          } catch (error) {
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        }
      })
    },

    openConfirmationModal() {
      this.$refs.sendEmailModal.show();
    },

    repeateAgain() {
      this.sendEmailForm.emails.push({
        id: this.nextEmailId = this.nextEmailId + 1,
        email: ""
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.emailRow[0].offsetHeight)
      })
    },

    removeItem(index) {
      if(this.sendEmailForm.emails.length <= 1) {
        this.sendEmailForm.emails[0].email = "";
      } else {
        this.sendEmailForm.emails.splice(index, 1)
        this.trTrimHeight(this.$refs.emailRow[0].offsetHeight)
      }
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.emailForm?.scrollHeight + 52)
      })
    },

    resetSendEmailForm() {
      setTimeout(()=>{
        this.sendEmailForm = { emails: [{ id: 1, email: this.contactDetails.email }], message: '', file: '' };
        this.nextEmailId = 1;
      },500)
    },

    checkDuplicate(index, e) {
      const exist = this.sendEmailForm.emails.find((it, indx) => e === it.email && index !== indx);
      if(exist) {
        this.isError = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email is already added. Please enter different email.',
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      } else {
        console.log("error ",index)
        this.isError = false;
      }
    },

    onChange(e) {
        const { files } = e.target;
        this.filePreview = files[0]?.name;
        this.sendEmailForm.file = files[0];
    },

    removeFile() {
      this.sendEmailForm.file = "";
      this.filePreview = "";
    },

    async getAgentName() {
      try {
        let userId = this.hotelDetails ? this.hotelDetails.userId : this.flightDetails.userId; 
        const res = await CustomerAdminService.getAgentDetails(userId, this.$route.query?.wl_code)
        if (resConditionCheck(res.status) && res.data) {
          this.agentName = res.data.data.firstName + ' ' + res.data.data.lastName; 
        } 
      } catch (error) {
        console.error("Error ---------->", error)
        const errorData = errorResponseHandler(error);
        this.$toast({
            component: ToastificationContent,
            props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
            },
        });
      }
    },
    
    onChange(e) {
        const { files } = e.target;
        this.filePreview = files[0]?.name;
        this.sendEmailForm.file = files[0];
    },

    removeFile() {
      this.sendEmailForm.file = "";
      this.filePreview = "";
    },
	}
};
</script>


<style>
.ql-editor {
  min-height: 100px;
  max-height: 350px;
}
</style>

<style lang="scss" scoped>
.custom_input_area {
    background: #efefef;
    padding: 0.438rem 1rem;
    margin-top: 5px;
    border-radius: 0.357rem;
    border: 1px solid #d8d6de;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.emailRemove{
  cursor: pointer;
  border-radius: 50%;
  transition: .25s ease-in-out;
}
.emailRemove:hover {
  background: rgb(187, 14, 14);
  color: #fff;
  box-shadow: 0px 0px 10px 2px #efefef;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
</style>