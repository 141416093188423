<template>
    <div>
        <b-modal
            id="modal-prevent-closing"
            ref="baggageModal"
            v-model="showModal"
            title="Edit Baggage Status"
            ok-title="Submit"
            no-close-on-backdrop
            no-close-on-esc
            cancel-variant="outline-secondary"
            centered
            @ok="handleOk"
            @cancel="handleClose"
            @close="handleClose"
        >
            <validation-observer ref="simpleRules">
                <b-form @submit.prevent>
                    <b-row>
                        <b-col md="12" lg="12">
                            <validation-provider
                                #default="{ errors }"
                                name="Title"
                                rules="required"
                            >
                                <b-form-group
                                    label="Select Status"
                                    label-for="mc-select-title"
                                    :state="errors.length > 0 ? false : null"
                                >
                                    <v-select
                                        v-model="baggageStatus"
                                        :options="BaggageStatusEnum"
                                        class="title"
                                        placeholder="Select Status"
                                        :dir="
                                            $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                        "
                                        :reduce="(val) => val.value"
                                        :clearable="false"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    VBModal,
    BRow,
    BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BaggageStatusEnum } from "@/helpers/constant";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
    components: {
        BFormGroup,
        BFormInput,
        BModal,
        BForm,
        BRow,
        BCol,
        ValidationProvider,
        ValidationObserver,
        BaggageStatusEnum,
        vSelect,
    },

    directives: { "b-modal": VBModal, Ripple },

    props: {
        toggleBaggageModal: {
            type: Boolean,
            default: false,
        },

        baggageDetails: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            showModal: this.toggleBaggageModal,
            BaggageStatusEnum,
            baggageStatus: this.baggageDetails.status || ""
        };
    },

    created() {
        console.log("In created", this.baggageDetails)
    },

    methods: {
        handleClose() {
            this.$emit("on-cancel");
        },

        handleOk() {
            this.$emit("on-submit",this.baggageStatus);
        },
    },
};
</script>
