<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-for="h-status"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="bookingStatus"
                    :options="BookingStatusEnum"
                    class="title"
                    placeholder="Select Title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    :disabled="readProperty"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="supplier id"
                rules="max:20"
              >
                <b-form-group
                  label="Supplier ID"
                  label-for="h-supplierId"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-supplierId"
                    v-model="supplierBookingId"
                    :readonly="readProperty"
                    type="text"
                    placeholder="Supplier Id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="supplier"
                rules="max:30"
              >
                <b-form-group
                  label="Supplier"
                  label-for="h-supplier"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-supplier"
                    v-model="supplier_name"
                    :readonly="readProperty"
                    placeholder="Supplier"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="hotel name"
                rules="required"
              >
                <b-form-group
                  label="Hotel Name"
                  label-for="h-hotel"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-hotel"
                    v-model="hotel_name"
                    :readonly="readProperty"
                    placeholder="Hotel Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="destination"
                rules="required"
              >
                <b-form-group
                  label="Destination"
                  label-for="h-destination"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-destination"
                    v-model="destination"
                    :readonly="readProperty"
                    type="text"
                    placeholder="Destination"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-group
                  label="Address"
                  label-for="h-hotel"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-hotel"
                    v-model="address"
                    :readonly="readProperty"
                    placeholder="Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="city name"
              >
                <b-form-group
                  label="City Name"
                  label-for="h-hotel"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-hotel"
                    v-model="city_name"
                    :readonly="readProperty"
                    placeholder="City Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="postal code"
              >
                <b-form-group
                  label="Postal Code"
                  label-for="h-hotel"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-hotel"
                    v-model="postal_code"
                    :readonly="readProperty"
                    placeholder="Postal Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="hotel telephone"
              >
                <b-form-group
                  label="Hotel Telephone"
                  label-for="h-hotel"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-hotel"
                    v-model="hotel_telephone"
                    :readonly="readProperty"
                    placeholder="Hotel Telephone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- <b-col cols="12">
            <b-form-group
              label="Star rating"
              label-for="h-star"
              label-cols-md="4"
            >
              <b-form-input
                id="h-star"
                type="text"
                placeholder="Star"
              />
            </b-form-group>
          </b-col> -->

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="checkin date"
                rules="required"
              >
                <b-form-group
                  label="Checkin date"
                  label-for="h-checkin"
                  label-cols-md="4"
                >
                  <date-picker
                    v-model="checkin_date"
                    :disabled="readProperty"
                    style="width: 100%"
                    type="date"
                    format="YYYY.MM.DD"
                    placeholder="Select date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="checkout date"
                rules="required"
              >
                <b-form-group
                  label="Checkout date"
                  label-for="h-checkout"
                  label-cols-md="4"
                >
                  <date-picker
                    v-model="checkout_date"
                    :disabled="readProperty"
                    style="width: 100%"
                    type="date"
                    format="YYYY.MM.DD"
                    placeholder="Select date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <b-form-group
                label="Nights"
                label-for="h-nights"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-nights"
                  v-model="nights"
                  :readonly="true"
                  type="text"
                  placeholder="Nights"
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row
            v-for="(item, index) in hotelDetails.moduleInfo.rooms"
            :key="index"
            >
              <b-col lg="6">
                <b-form-group
                  :label="`Room Type ${index + 1}`" 
                  label-for="h-room"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-room"
                    v-model="item.room_type"
                    :disabled="readProperty"
                    type="text"
                    placeholder="Room Type"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group
                  :label="`Board Type ${index + 1}`"
                  label-for="h-board"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="board_basis"
                    :options="BoardBasisEnum"
                    class="title"
                    placeholder="Select Title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    :disabled="readProperty"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          <div class="mt-2" v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="editDetails"
            >
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              v-if="readProperty"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="mr-1"
              @click="editMode"
            >
              Edit
            </b-button>

            <b-button
              v-if="!readProperty"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="mr-1"
              @click="resetForm"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BookingStatus,
  BoardBasis,
  BookingStatusEnum,
  resConditionCheck,
  BoardBasisEnum,
  RoleEnum,
} from "@/helpers/constant";
import getDateDiff from "@/helpers/dateDiff";
import editBookingService from "@/services/booking/editBooking.service";
import errorResponseHandler from "@/services/errorHandler";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  props: {
    hotelDetails: {
      type: Object,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      BookingStatus,
      BookingStatusEnum,
      BoardBasisEnum,
      bookingStatus: this.hotelDetails.bookingStatus || "",
      supplierBookingId: this.hotelDetails.bookingReference || "-",
      hotel_name: this.hotelDetails.moduleInfo.hotel_name || "",
      checkin_date:
        moment(this.hotelDetails.searchCriteria.checkin_date).toDate() || "",
      checkout_date:
        moment(this.hotelDetails.searchCriteria.checkout_date).toDate() || "",
      destination: this.hotelDetails.searchCriteria.value || "",
      supplier_name: this.hotelDetails.moduleInfo.supplier_name || "",
      address: this.hotelDetails.moduleInfo.address || "",
      postal_code: this.hotelDetails.moduleInfo.postal_code || "",
      hotel_telephone: this.hotelDetails.moduleInfo.hotel_telephone || "",
      city_name: this.hotelDetails.moduleInfo.city_name || "",
      nights: "",
      readProperty: true,
      room_type: this.getRooms(this.hotelDetails.moduleInfo.rooms),
      board_basis: this.hotelDetails.moduleInfo.rooms[0].board_basis.toUpperCase(),
      loading: false,
      RoleEnum,
			checkLoginRole,
			checkAccess: {},
      wl_code: axios.defaults.headers.wl_code,
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    console.log("hotel details", this.hotelDetails);
    this.nights = getDateDiff(this.checkin_date, this.checkout_date);
  },

  methods: {
    getRooms(rooms) {
      let title = "";
      const obj = {};
      for (let i = 0; i < rooms.length; i += 1) {
        if (obj[rooms[i].room_type]) {
          obj[rooms[i].room_type] += 1;
        } else {
          obj[rooms[i].room_type] = 1;
        }
      }
      const ln = Object.keys(obj).length;
      Object.keys(obj).forEach((e, i) => {
        title += `${rooms.length > 1 ? `${obj[e]} ` : ""}${
          obj[e] > 1 ? "× " : ""
        }${e}`;
        if (i === ln - 2) {
          title += ` and `;
        } else if (i < ln - 2) {
          title += ", ";
        }
      });
      return title;
    },

    editMode() {
      this.readProperty = false;
    },

    resetForm() {
      this.bookingStatus = this.hotelDetails.bookingStatus || "",
      this.supplierBookingId = this.hotelDetails.bookingReference || "-",
      this.hotel_name = this.hotelDetails.moduleInfo.hotel_name || "",
      this.checkin_date =
        moment(this.hotelDetails.searchCriteria.checkin_date).toDate() || "",
      this.checkout_date =
        moment(this.hotelDetails.searchCriteria.checkout_date).toDate() || "",
      this.destination = this.hotelDetails.searchCriteria.value || "",
      this.supplier_name =  this.hotelDetails.moduleInfo.supplier_name || "",
      this.address = this.hotelDetails.moduleInfo.address || "",
      this.postal_code = this.hotelDetails.moduleInfo.postal_code || "",
      this.hotel_telephone = this.hotelDetails.moduleInfo.hotel_telephone || "",
      this.city_name = this.hotelDetails.moduleInfo.city_name || "",
      this.readProperty = true,
      this.room_type = this.hotelDetails.moduleInfo.rooms.map(e => { return e.room_type }),
      this.board_basis = this.hotelDetails.moduleInfo.rooms[0].board_basis.toUpperCase()
    },

    async editDetails() {
      const valid = await this.$refs.simpleRules.validate();
      const payload = {
        booking_id: this.hotelDetails.bookingInstallments[0].bookingId,
        supplier_booking_id: this.supplierBookingId,
        status: this.bookingStatus,
        destination: this.destination,
        checkout_date: moment(this.checkout_date).format("YYYY-MM-DD"),
        checkin_date: moment(this.checkin_date).format("YYYY-MM-DD"),
        board_type: this.board_basis,
        hotel_name: this.hotel_name,
        supplier: this.supplier_name,
        address: this.address,
        postal_code: this.postal_code,
        telephone: this.hotel_telephone,
        city_name: this.city_name,
        room_type: this.hotelDetails.moduleInfo.rooms.map(e => {return e.room_type})
      };
      if (valid) {
        this.loading = true;
        try {
          const res = await editBookingService.changeModuleWiseBookingDetails(
            payload,
            this.$route.query?.wl_code
          );
          if (resConditionCheck(res.status) && res.data.message) {
            setTimeout(() => {
              this.loading = false;
            }, 3000);
            this.$emit("callBookingDetailbyId");
            this.readProperty = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        } catch (error) {
          setTimeout(() => {
            this.loading = false;
          }, 3000);

          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    }
  }
};
</script>
