<template>
    <div>
        <!-- <div
            v-if="!insuranceArray.length || !insuranceDetails"
            class="m-auto text-center"
        >
            <p>No Insurance Found</p>
        </div> -->
          <b-card v-if="!insuranceArray.length || !insuranceDetails" class="m-auto text-center">
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span
                            ><strong
                                >Insurance not available for this
                                booking!</strong
                            >
                        </span>
                    </div>
                </b-alert>
            </b-card>
        <b-row>
            <b-col
                v-for="(insurance, index) in insuranceArray"
                :key="index"
                md="6"
            >
                <b-card class="mt-1">
                    <div style="justify-content: space-between" class="d-flex">
                        <!-- <h3 class="pb-2">
              <b-alert variant="primary" show>
              {{ insurance.name }}
              </b-alert>
            </h3> -->
                        <h5>
                            <b-alert variant="primary" show="">
                                <div class="alert-body">
                                   <b>{{ insurance.name }}</b>
                                </div>
                            </b-alert>
                        </h5>
                        <feather-icon
                            v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1"
                            style="cursor: pointer"
                            icon="EditIcon"
                            size="21"
                            @click="enableReadOnly(index)"
                        />
                    </div>
                    <validation-observer ref="simpleRules">
                        <b-form :class="`index${index}`" @submit.prevent>
                            <validation-provider
                                #default="{ errors }"
                                name="status"
                                rules="required"
                            >
                                <b-form-group
                                    label="Status"
                                    label-for="h-status"
                                    label-cols-md="4"
                                >
                                    <v-select
                                        v-model="insurance.status"
                                        :options="InsuranceStatus"
                                        class="title"
                                        placeholder="Select Title"
                                        :dir="
                                            $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                        "
                                        :reduce="(val) => val.value"
                                        :clearable="false"
                                        :disabled="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="supplier code"
                                rules="required"
                            >
                                <b-form-group
                                    label="Supplier"
                                    label-cols-md="4"
                                >
                                    <b-form-input
                                        v-model="insurance.supplierCode"
                                        :readonly="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                        placeholder="Supplier"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>
                            <!-- <b-form-group label="Supplier ID" label-cols-md="4">
                  <b-form-input :readonly="true" placeholder="Supplier ID" />
                </b-form-group> -->

                            <validation-provider
                                #default="{ errors }"
                                name="insurance name"
                                rules="required"
                            >
                                <b-form-group label="Type" label-cols-md="4">
                                    <b-form-input
                                        v-model="insurance.name"
                                        :readonly="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                        placeholder="Type"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="start date"
                                rules="required"
                            >
                                <b-form-group
                                    label="Start Date"
                                    label-cols-md="4"
                                >
                                    <date-picker
                                        v-model="insurance.startDate"
                                        style="width: 100%"
                                        type="date"
                                        format="YYYY.MM.DD"
                                        placeholder="Select date"
                                        :disabled="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="end date"
                                rules="required"
                            >
                                <b-form-group
                                    label="End Date"
                                    label-cols-md="4"
                                >
                                    <date-picker
                                        v-model="insurance.endDate"
                                        style="width:100%"
                                        type="date"
                                        format="YYYY.MM.DD"
                                        placeholder="Select date"
                                        :disabled="true"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <b-form-group label="Days" label-cols-md="4">
                                <b-form-input
                                    :value="
                                        getDateDiff(
                                            insurance.startDate,
                                            insurance.endDate
                                        )
                                    "
                                    :readonly="true"
                                    placeholder="Days"
                                />
                            </b-form-group>

                            <validation-provider
                                #default="{ errors }"
                                name="policy code"
                                rules="required"
                            >
                                <b-form-group
                                    label="Policy Code"
                                    label-cols-md="4"
                                >
                                    <b-form-input
                                        v-model="insurance.policy_code"
                                        :readonly="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                        placeholder="Policy code"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="amount"
                                rules="required"
                            >
                                <b-form-group label="Amount" label-cols-md="4">
                                    <b-form-input
                                        v-model="insurance.amount"
                                        :readonly="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                        placeholder="Amount"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <b-col v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                    class="mt-1"
                                    :disabled="
                                        (index === 0 ? loading : loading1) ||
                                            (index === 0 ? readOnly : readOnly1)
                                    "
                                    @click="updateInsurance(insurance, index)"
                                >
                                    Submit
                                    <div
                                        v-if="index === 0 ? loading : loading1"
                                        class="spinner-border spinner-border-sm"
                                    />
                                </b-button>
                            </b-col>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BCol,
    BRow,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import getDateDiff from "@/helpers/dateDiff";
import { InsuranceStatus, resConditionCheck, RoleEnum, } from "@/helpers/constant";
import editBookingService from "@/services/booking/editBooking.service";
import errorResponseHandler from "@/services/errorHandler";
import { FormWizard, TabContent } from "vue-form-wizard";
import moment from "moment";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';

export default {
    components: {
        BCol,
        BRow,
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        vSelect,
        DatePicker,
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BAlert,
    },

    props: {
        insuranceDetails: {
            type: Array,
            required: false,
        },
        isLoaded: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            getDateDiff,
            refFormWizard: "",
            readOnly: true,
            readOnly1: true,
            index0: true,
            insuranceArray: [],
            InsuranceStatus,
            insuranceStatus: "",
            loading: false,
            loading1: false,
            RoleEnum,
			checkLoginRole,
			checkAccess: {},
            wl_code: axios.defaults.headers.wl_code,
        };
    },

    directives: { Ripple },

    created() {
        if (this.insuranceDetails) {
            console.log("insurance details", this.insuranceDetails);
            this.insuranceArray = this.insuranceDetails?.filter(
                (e) => e.type === "Insurance"
            );
            this.insuranceArray = this.insuranceArray.map((e) => ({
                ...e,
                startDate: moment(e.startDate).toDate(),
                endDate: moment(e.endDate).toDate(),
            }));
        }
    },

    mounted() {
        this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    },

    methods: {
        enableReadOnly(index) {
            index === 0 ? (this.readOnly = false) : (this.readOnly1 = false);
        },

        async updateInsurance(insurance, index) {
            index === 0 ? (this.loading = true) : (this.loading1 = true);
            const payload = {
                insurance_id: insurance.id,
                supplier_code: insurance.supplierCode,
                policy_code: insurance.policy_code,
                status: insurance.status
                    ? insurance.status
                    : this.insuranceStatus,
                insurance_name: insurance.name,
                start_date: moment(insurance.startDate).format("YYYY-MM-DD"),
                amount: insurance.amount,
            };
            try {
                const res = await editBookingService.updateInsurance(
                    this.$route.params.id,
                    payload,
                    this.$route.query?.wl_code
                );
                if (resConditionCheck(res.status) && res.data.message) {
                    setTimeout(() => {
                        index === 0
                            ? (this.loading = false)
                            : (this.loading1 = false);
                    }, 3000);

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.data.message,
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                    index === 0
                        ? (this.readOnly = true)
                        : (this.readOnly1 = true);
                }
            } catch (error) {
                setTimeout(() => {
                    index === 0
                        ? (this.loading = false)
                        : (this.loading1 = false);
                }, 3000);

                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
