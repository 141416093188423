<template>
    <div>
        <form-wizard
            ref="refFormWizard"
            color="#3f8d85"
            :title="null"
            :subtitle="null"
            back-button-text="Previous"
            :hide-buttons="true"
            class="checkout-form-wizard steps-transparent"
        >
            <tab-content title="Insurance Details">
                <insurance-details
                    @next-step="formWizardNextStep"
                    :insuranceDetails="insuranceDetails"
                    :is-loaded="isLoaded"
                />
            </tab-content>
            <tab-content title="Priority Boarding" :before-change="test">
                <priority-boarding :priority-details="priorityDetails" />
            </tab-content>
            <tab-content v-if="bookingType !== 2" title="Transfer">
                <transfers :transferDetails="transferDetails" />
            </tab-content>
        </form-wizard>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ref } from "@vue/composition-api";
import InsuranceDetails from "./InsuranceDetails.vue";
import PriorityBoarding from "./PriorityBoarding.vue";
import Transfers from "./Transfers.vue";

export default {
    components: {
        FormWizard,
        TabContent,
        InsuranceDetails,
        PriorityBoarding,
        Transfers,
    },

    props: {
        insuranceDetails: {
            type: Array,
            required: false,
        },
        priorityDetails: {
            type: Object,
            required: false,
        },
        transferDetails: {
            type: Array,
            required: false,
        },
        isLoaded: {
            type: Boolean,
            required: true,
        },
        bookingType: {
            type: Number,
            required: true
        },
    },

    data() {
        return {
            refFormWizard: "",
        };
    },

    mounted() {
        this.$refs.refFormWizard.activateAll()
        console.log("TRANSFERS details in extras", this.transferDetails);
    },

    setup() {
        const refFormWizard = ref(null);
        const formWizardNextStep = () => {
            refFormWizard.value.nextTab();
        };

        return {
            refFormWizard,
            formWizardNextStep,
        };
    },

    methods: {
        test() {
            console.log("Helloi")
            return true;
        }
    }

};
</script>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
    .wizard-tab-content {
        box-shadow: none !important;
        background: transparent !important;
        padding: 0;
    }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
