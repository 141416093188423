<template>
  <div ref="topDiv">
    <payment-amount-modal
      v-if="toggleModal"
      ref="paymentAmountModal"
      :payment-amount-object="paymentAmountObject"
      :toggle-payment-amount-modal="toggleModal"
      @on-cancel="onCancel"
      @on-submit="onSubmit"
    />

    <payment-modal
      v-if="togglePaymentModal"
      ref="paymentModal"
      :payment-object="paymentObject"
      :toggle-payment-modal="togglePaymentModal"
      @on-cancel="onModalCancel"
      @on-submit="onModalSubmit"
    />

    <div class="d-flex justify-content-between mb-1 mt-3">
      <h5 class="mr-2">Payment</h5>
      <feather-icon
        v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1"
        icon="EditIcon"
        size="21"
        style="cursor: pointer"
        @click="openModal"
      />
    </div>

    <b-table
      ref="refUserListTable"
      class="position-relative"
      responsive
      :items="paymentDetails"
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(No)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(Type)="data">
        {{ PaymentType(data.item.paymentVia) }}
      </template>

      <template #cell(Deadline)="data" >
        <div 
          style="width: 150px"
          v-if="
            (data.item.paymentVia === 1 /* && data.item.paymentStatus === 1 */) ||
              (!checkCondition(data.item) && data.item.paymentVia !== 2)
          "
        >
          {{ calculateInstallmentDeadline(data.item) }}
        </div>
      </template>

      <!-- <template #cell(Amount)="data">
        {{ data.item.amount ? data.item.amount : "-" }}
      </template> -->

      <template #cell(Amount)="data">
        <div
          style="width:150px"
          v-if="
            (data.item.paymentVia === 1 && data.item.paymentStatus === 1) ||
            (!checkCondition(data.item) && data.item.paymentVia !== 2) ||
            (data.item.paymentVia === 1 && data.item.paymentStatus !== 1 && checkCondition(data.item)) ||
            (data.item.paymentVia === 2)
          "
        >
          {{
            data.item.amount
              ? data.item.amount
              : "-"
          }}
        </div>        
      </template>

      <template #cell(transaction_id)="data">
        <div
          style="width:150px"
          v-if="
            (data.item.paymentVia === 1 && data.item.paymentStatus === 1) ||
            (!checkCondition(data.item) && data.item.paymentVia !== 2) ||
            (data.item.paymentVia === 1 && data.item.paymentStatus !== 1 && checkCondition(data.item)) ||
            (data.item.paymentVia === 2)
          "
        >
          {{
            data.item.paymentTransactionId
              ? data.item.paymentTransactionId
              : "-"
          }}
        </div>
      </template>

      <template #cell(status)="data">
        <div
          v-if="
            (data.item.paymentVia === 1 && data.item.paymentStatus === 1) ||
            (!checkCondition(data.item) && data.item.paymentVia !== 2) ||
            (data.item.paymentVia === 1 && data.item.paymentStatus !== 1 && checkCondition(data.item)) ||
            (data.item.paymentVia === 2)
          "
        >
          {{ PaymentStatus(data.item.paymentStatus) }}
        </div>
      </template>

      <template #cell(payment_date)="data">
        <div
          style="width:150px"
          v-if="
            (data.item.paymentVia === 1 && data.item.paymentStatus === 1) ||
            (!checkCondition(data.item) && data.item.paymentVia !== 2) ||
            (data.item.paymentVia === 1 && data.item.paymentStatus !== 1 && checkCondition(data.item)) ||
            (data.item.paymentVia === 2)
          "
        >
          {{
            data.item.paymentDate
              ? moment(data.item.paymentDate).format("YYYY-MM-DD")
              : "-"
          }}
        </div>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            @click="openPaymentModal(data.item, data.index)"
            :disabled="!(
              ((checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1) 
              &&
              ((data.item.paymentVia === 1 &&
              data.item.paymentStatus !== 1 &&
              checkCondition(data.item)) ||
              data.item.paymentVia === 2)
            )"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
        </b-dropdown>
        <!-- <feather-icon
          @click="((checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1) ? changeInstallmentData(data.item) : null"
          icon="SaveIcon"
          size="21"
          :style="`cursor: ${((checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1) ? 'pointed' : 'not-allowed'}`"
        /> -->
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BDropdown, BDropdownItem, BFormGroup, BFormSelect, BFormInput } from "bootstrap-vue";
import {
  PaymentStatus,
  PaymentType,
  PaymentStatusEnum,
  resConditionCheck,
  RoleEnum,
} from "@/helpers/constant";
import * as moment from "moment";
import bookingService from "@/services/booking/booking.service";
import errorResponseHandler from "@/services/errorHandler";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import editBookingService from "@/services/booking/editBooking.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';
import paymentModal from "@/views/modal/PaymentModal.vue"
import paymentAmountModal from "@/views/modal/PaymentAmountModal.vue"

export default {
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BFormInput,
    DatePicker,
    paymentModal,
    paymentAmountModal
  },

  props: {
    paymentDetails: {
      type: Array,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      items: [],
      installmentDeadline: "",
      PaymentStatus,
      PaymentType,
      moment,
      PaymentStatusEnum,
      tableColumns: [
        { key: "No" },
        { key: "Type" },
        { key: "Deadline" },
        { key: "Amount" },
        { key: "transaction_id" },
        { key: "status" },
        { key: "payment_date" },
        { key: "actions" }
      ],
      RoleEnum,
			checkLoginRole,
			checkAccess: {},
      wl_code: axios.defaults.headers.wl_code,
      paymentObject: {},
      togglePaymentModal: false,
      paymentAmountObject: {},
      toggleModal: false,
      paymentAmountDetails: {}
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
  },

  methods: {

    async openModal() {
      this.paymentAmountDetails = {
        booking_module: this.paymentDetails[0].moduleId,
        first_installment_id: this.paymentDetails[0].id,
        first_installment_amount: this.paymentDetails[0].amount,
        first_installment_status: this.paymentDetails[0].paymentStatus,
        second_installment_id: this.paymentDetails[1] ? this.paymentDetails[1]?.id : "",
        second_installment_amount: this.paymentDetails[1] ? this.paymentDetails[1]?.amount : "",
        second_installment_status: this.paymentDetails[1] ? this.paymentDetails[1].paymentStatus : "",
        module: ""
      }
      console.log("detailsssssssssssss", this.paymentAmountDetails)
      this.toggleModal = true;
      this.paymentAmountObject = this.paymentAmountDetails;
    },

    async openPaymentModal(data, index) {
      this.togglePaymentModal = true;
      (this.paymentObject = data), (this.index = index);
    },

    onCancel() {
      this.toggleModal = false;
    },

    onModalCancel() {
      this.togglePaymentModal = false;
    },

    async onSubmit(paymentAmountForm) {
      const valid = await this.$refs.paymentAmountModal.validationForm();

      if (valid) {
        try {
          this.toggleModal = false;
          if(paymentAmountForm.first_installment_id || paymentAmountForm.second_installment_id) {
            const res = await editBookingService.editPaymentAmountData(
              this.$route.params.id,
              paymentAmountForm,
              this.$route.query?.wl_code
            );
            if (resConditionCheck(res.status) && res.data.message) {
              this.$emit("callBookingDetailbyId");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          }
        } catch (error) {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },

    async onModalSubmit(paymentEditForm) {
      const valid = await this.$refs.paymentModal.validationForm();

      const payload = {
        booking_quote_id: this.$route.params.id,
        amount: paymentEditForm.amount,
        transaction_id: paymentEditForm.transaction_id,
        payment_date: paymentEditForm.payment_date,
        installment_status: paymentEditForm.status
      };

      if (valid) {
        try {
          const res = await editBookingService.editInstallmentData(
            paymentEditForm.id,
            payload,
            this.$route.query?.wl_code
          );
          if (resConditionCheck(res.status) && res.data.message) {
            this.$emit("callBookingDetailbyId");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success"
              }
            });
          }
        } catch (error) {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
      this.togglePaymentModal = false;
    },

    // async changeInstallmentStatus(installmentId, status) {
    //   try {
    //     const res = await bookingService.changeInstallmentStatus(
    //       installmentId,
    //       { installment_status: status }
    //     );
    //     if (resConditionCheck(res.status) && res.data.data) {
    //       this.$emit("callBookingDetailbyId");
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: res.data.message,
    //           icon: "CheckIcon",
    //           variant: "success"
    //         }
    //       });
    //     }
    //   } catch (error) {
    //     const errorData = errorResponseHandler(error);
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: { title: errorData, icon: "X-CircleIcon", variant: "danger" }
    //     });
    //   }
    // },

    // async changeInstallmentData(item) {
    //   try {
    //     if(this.checkLoginRole() === this.RoleEnum.SuperAdmin ? true : this.checkAccess.Edit){
    //       const payload = {
    //         transaction_id: item.paymentTransactionId,
    //         payment_date: item.paymentDate ? moment(item.paymentDate).format("YYYY-MM-DD") : null,
    //         installment_status: item.paymentStatus,
    //         booking_quote_id: this.$route.params.id,
    //         amount: item.amount
    //       };

    //       const res = await editBookingService.editInstallmentData(
    //         item.id,
    //         payload,
    //         this.$route.query?.wl_code
    //       );
    //       if (resConditionCheck(res.status) && res.data.message) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: res.data.message,
    //             icon: "CheckIcon",
    //             variant: "success"
    //           }
    //         });
    //       }
    //     }  
    //   } catch (error) {
    //     const errorData = errorResponseHandler(error);
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: { title: errorData, icon: "X-CircleIcon", variant: "danger" }
    //     });
    //   }
    // },

    checkCondition(item) {
      // check today day greater than installment deadline and status !== 1
      return true; //should be editable always
      return (
        moment(new Date()).format("YYYY-MM-DD") >
        moment(item.installmentDate)
          .add(1, "d")
          .format("YYYY-MM-DD")
      );
    },

    calculateInstallmentDeadline(item) {
      if (item.paymentVia === 2) {
        return moment(item.installmentDate)
          .add(1, "d")
          .format("YYYY-MM-DD");
      }
      if (this.paymentDetails.length > 1 && item.paymentVia === 1) {
        return moment(item.installmentDate).format("YYYY-MM-DD");
      }
      return "-";
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
