<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="flightModal"
      v-model="showModal"
      title="Edit Flight Details"
      ok-title="Submit"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-secondary"
      centered
      @ok="handleOk"
      @cancel="handleClose"
      @close="handleClose"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <validation-provider #default="{ errors }" name="Title">
                <b-form-group
                  label="Title"
                  label-for="mc-select-title"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="flightForm.booking_status"
                    :options="BookingStatusEnum"
                    class="title"
                    placeholder="Select Status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider
                rules="max:20"
                #default="{ errors }"
                name="supplier id"
              >
                <b-form-group label="Supplier Id">
                  <b-form-input
                    v-model="flightForm.supplier_booking_id"
                    id="last-input"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  VBModal,
  BForm,
  BFormInput
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { BookingStatusEnum } from "@/helpers/constant";

export default {
  components: {
    BFormGroup,
    BModal,
    BCol,
    BRow,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput
  },

  directives: { "b-modal": VBModal, Ripple },

  props: {
    flightObject: {
      type: Object,
      required: true
    },

    toggleModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModal: this.toggleModal,
      valid: "",
      flightForm: {
        booking_status: this.flightObject.bookingStatus,
        supplier_booking_id: this.flightObject.bookingReference || ""
      },
      BookingStatusEnum
    };
  },

  methods: {
    handleOk() {
      this.$emit("on-submit", this.flightForm);
    },

    handleClose() {
      this.$emit("on-cancel");
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        valid = !!success;
      });
      return valid;
    }
  }
};
</script>
