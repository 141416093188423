<template>
  <div>
    <b-modal id="modal-prevent-closing" ref="paymentAmountModal" v-model="showModal" title="Edit Payment Amount Details"
      ok-title="Submit" no-close-on-backdrop no-close-on-esc cancel-variant="outline-secondary" centered @ok="handleOk"
      @cancel="handleClose" @close="handleClose">
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <validation-provider #default="{ errors }" rules="required|max:40" name="transaction id">
                <b-form-group label="First Installment Amount">
                  <b-form-input v-model="paymentAmountEditForm.first_installment_amount" name="first installment amount"
                    placeholder="" :readonly="paymentAmountEditForm.first_installment_status === 1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" v-if="paymentAmountEditForm.second_installment_id !== ''">
              <validation-provider #default="{ errors }" rules="required|max:40" name="transaction id">
                <b-form-group label="Second Installment Amount">
                  <b-form-input v-model="paymentAmountEditForm.second_installment_amount"
                    name="second installment amount" placeholder=""
                    :readonly="paymentAmountEditForm.second_installment_status === 1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider #default="{ errors }" name="module" rules="required">
                <b-form-group label="Module" label-for="mc-module" :state="errors.length > 0 ? false : null">
                  <v-select v-model="paymentAmountEditForm.module" :options="paymentChangeModuleOptions" class="title"
                    placeholder="Select Module" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value" :clearable="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BForm,
  VBModal,
  BRow,
  BCol
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueTimepicker from "vue2-timepicker";
import { required } from "@/@core/utils/validations/validations";
import { PaymentChangeModuleOptions, PaymentOnlyHotelOptions, PaymentOnlyFlightOptions, resConditionCheck, PaymentStatus } from "@/helpers/constant";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    VBModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    vSelect,
    flatPickr,
    VueTimepicker
  },

  props: {
    togglePaymentAmountModal: {
      type: Boolean,
      default: false
    },

    paymentAmountObject: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      required,
      paymentChangeModuleOptions: [],
      PaymentChangeModuleOptions,
      PaymentOnlyHotelOptions,
      PaymentOnlyFlightOptions,
      showModal: this.togglePaymentAmountModal,
      paymentAmountEditForm: {
        first_installment_amount: this.paymentAmountObject.first_installment_amount || "",
        first_installment_status: this.paymentAmountObject.first_installment_status || "",
        second_installment_id: this.paymentAmountObject.second_installment_id,
        second_installment_amount: this.paymentAmountObject.second_installment_amount || "",
        second_installment_status: this.paymentAmountObject.second_installment_status || "",
        module: ""
      },
      submitForm: {}
    };
  },

  mounted() {
    if (this.paymentAmountObject.booking_module === 3) {
      this.paymentChangeModuleOptions = PaymentChangeModuleOptions
    } else if (this.paymentAmountObject.booking_module === 2) {
      this.paymentChangeModuleOptions = PaymentOnlyFlightOptions
    } else if (this.paymentAmountObject.booking_module === 1) {
      this.paymentChangeModuleOptions = PaymentOnlyHotelOptions
    }
  },

  methods: {
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.paymentAmountEditForm.first_installment_status !== 1
        ? this.submitForm.first_installment_id = this.paymentAmountObject.first_installment_id : ""

      this.paymentAmountEditForm.first_installment_status !== 1
        ? this.submitForm.first_installment_amount = this.paymentAmountEditForm.first_installment_amount : ""

      this.paymentAmountObject.second_installment_id && this.paymentAmountObject.second_installment_id !== "" && this.paymentAmountEditForm.second_installment_status !== 1
        ? this.submitForm.second_installment_id = this.paymentAmountObject.second_installment_id : ""

      this.paymentAmountObject.second_installment_id && this.paymentAmountObject.second_installment_id !== "" && this.paymentAmountEditForm.second_installment_status !== 1
        ? this.submitForm.second_installment_amount = this.paymentAmountEditForm.second_installment_amount : ""

      this.submitForm.module = this.paymentAmountEditForm.module

      this.$emit("on-submit", this.submitForm);
    },
    handleClose() {
      this.$emit("on-cancel");
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        valid = !!success;
      });
      return valid;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
