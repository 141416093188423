<template>
    <div>
        <div
            v-if="!transferArray.length || !transferDetails"
            class="m-auto text-center"
        >
            <b-card>
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span
                            ><strong
                                >Transfer Service not available for this
                                booking!</strong
                            >
                        </span>
                    </div>
                </b-alert>
            </b-card>
        </div>
        <b-row>
            <b-col v-for="(transfer, index) in transferArray" :key="index">
                <b-card class="mt-1">
                    <div
                        class="mb-2 d-flex"
                        style="justify-content: space-between"
                    >
                        <h5>
                            <b-alert variant="primary" show="">
                                <div class="alert-body">
                                    <b v-if="transfer.type === 'outbound'">
                                        <b>Airport-Hotel</b>
                                    </b>

                                    <b v-if="transfer.type === 'inbound'">
                                        <b>Hotel-Airport</b>
                                    </b>
                                </div>
                            </b-alert>
                        </h5>
                        <feather-icon
                            v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1"
                            style="cursor: pointer"
                            icon="EditIcon"
                            size="21"
                            @click="enableReadOnly(index)"
                        />
                    </div>
                    <validation-observer ref="simpleRules">
                        <b-form :class="`index${index}`" @submit.prevent>
                            <validation-provider
                                #default="{errors}"
                                name="status"
                                rules="required"
                            >
                                <b-form-group
                                    label="Status"
                                    label-for="h-status"
                                    label-cols-md="4"
                                >
                                    <v-select
                                        v-model="transfer.status"
                                        :options="TransferStatusEnum"
                                        class="status"
                                        placeholder="Select Status"
                                        :dir="
                                            $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                        "
                                        :reduce="(val) => val.value"
                                        :clearable="false"
                                        :disabled="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="supplier"
                                rules="required"
                            >
                                <b-form-group
                                    label="Supplier"
                                    label-cols-md="4"
                                >
                                    <b-form-input
                                        v-model="transfer.vendor"
                                        placeholder="Supplier"
                                        :readonly="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="supplier id"
                                rules="required"
                            >
                                <b-form-group
                                    label="Supplier ID"
                                    label-cols-md="4"
                                >
                                    <b-form-input
                                        v-model="transfer.vendor_code"
                                        placeholder="Supplier ID"
                                        :readonly="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{errors}"
                                name="type"
                                rules="required"
                            >
                                <b-form-group
                                    label="Transfer Type"
                                    label-cols-md="4"
                                >
                                    <v-select
                                        v-model="transfer.vehicle"
                                        :options="TransferTypeEnum"
                                        class="type"
                                        placeholder="Select Transfer type"
                                        :dir="
                                            $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                        "
                                        :reduce="(val) => val.value"
                                        :clearable="false"
                                        :disabled="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="Transfer date"
                                rules="required"
                            >
                                <b-form-group
                                    label="Transfer date"
                                    label-cols-md="4"
                                >
                                    <date-picker
                                        v-model="transfer.transfer_date"
                                        style="width: 100%"
                                        type="date"
                                        format="YYYY.MM.DD"
                                        placeholder="Select date"
                                        :disabled="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                #default="{ errors }"
                                name="amount"
                                rules="required|positive|max:10"
                            >
                                <b-form-group label="Amount" label-cols-md="4">
                                    <b-form-input
                                        v-model="transfer.amount"
                                        placeholder="Amount"
                                        :readonly="
                                            index === 0 ? readOnly : readOnly1
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>
                            <b-col v-if="checkLoginRole() === RoleEnum.SuperAdmin ? true : checkAccess.Edit">
                                <b-button
                                    v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                    class="mt-1"
                                    :disabled="
                                        (index === 0 ? loading : loading1) ||
                                            (index === 0 ? readOnly : readOnly1)
                                    "
                                    @click="updateTransfer(index, transfer)"
                                >
                                    Submit
                                    <div
                                        v-if="index === 0 ? loading : loading1"
                                        class="spinner-border spinner-border-sm"
                                    />
                                </b-button>
                            </b-col>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BCol,
    BRow,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import * as moment from "moment";
import editBookingService from "@/services/booking/editBooking.service";
import { resConditionCheck } from "@/helpers";
import {TransferStatusEnum, TransferTypeEnum, RoleEnum} from '@/helpers/constant'
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';

export default {
    components: {
        BCol,
        BRow,
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        DatePicker,
        BAlert,
        ToastificationContent,
    },
    directives: { Ripple },

    props: {
        transferDetails: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            transferArray: [],
            readOnly: true,
            readOnly1: true,
            loading: false,
            loading1: false,
            TransferStatusEnum,
            TransferTypeEnum,
            checkLoginRole,
            checkAccess: {},
            RoleEnum,
            wl_code: axios.defaults.headers.wl_code,
        };
    },

    created() {
        this.transferArray = this.transferDetails;
        console.log("transfer", this.transferDetails);
        this.transferArray = this.transferArray.map((e) => ({
            ...e,
            transfer_date: moment(e.transfer_date).toDate(),
        }));
    },

    mounted() {
        this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    },

    methods: {
        enableReadOnly(index) {
            index === 0 ? (this.readOnly = false) : (this.readOnly1 = false);
        },

        async updateTransfer(index, transfer) {
            index === 0 ? (this.loading = true) : (this.loading1 = true);
            const payload = {
                type: transfer.type,
                status: transfer.status,
                supplier: transfer.vendor,
                supplier_id: transfer.vendor_code,
                transfer_type: transfer.vehicle,
                transfer_date: moment(transfer.transfer_date).format(
                    "YYYY-MM-DD"
                ),
                amount: transfer.amount,
            };

            try {
                const res = await editBookingService.updateTransfer(
                    this.$route.params.id,
                    payload,
                    this.$route.query?.wl_code
                );
                if (resConditionCheck(res.status) && res.data.data) {
                    setTimeout(() => {
                        index === 0
                            ? (this.loading = false)
                            : (this.loading1 = false);
                    }, 3000);

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.data.message,
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                    index === 0
                        ? (this.readOnly = true)
                        : (this.readOnly1 = true);
                }
            } catch (error) {
                setTimeout(() => {
                    index === 0
                        ? (this.loading = false)
                        : (this.loading1 = false);
                }, 3000);
                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },
    },
};
</script>
