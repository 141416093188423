<template>
    <div>
        <b-table
            ref="refUserListTable"
            :items="items"
            class="position-relative"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
        >
            <template #cell(services)="data">
                {{ data.item.service }}
            </template>

            <template #cell(provider_cost)="data">
                {{ data.item.providerCost ? data.item.providerCost : "-" }}
            </template>

            <template #cell(markup)="data">
                {{ data.item.markup ? data.item.markup : "-" }}
            </template>

            <template #cell(retail_price)="data">
                {{ data.item.retailPrice ? data.item.retailPrice : "-" }}
            </template>
        </b-table>
    </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { required } from 'vee-validate/dist/rules';

export default {
    components: {
        BTable,
    },

    props: {
        financialDetails: {
            type: Object,
            required: true,
        },
        transferDetails: {
            type: Array,
            required: false,
        },
		priorityDetails: {
			type: Object,
			required: false
		},

		baggageDetails: { 
			type: Object,
			required: false
		},

        isLoaded: {
            type: Boolean,
            required: true,
        },
        bookingType: {
            type: Number,
            required: true
        },
    },

    data() {
        return {
            items: [],
            secondObject: {},
            tableColumns: [
                { key: "Services" },
                { key: "provider_cost" },
                { key: "markup" },
                { key: "retail_price" },
            ],
        };
    },

    created() {
        console.log("transfer details in financial", this.baggageDetails);
        this.getData();
    },

    methods: {
        getData() {
            const hotelDetails = this.financialDetails.bookings.find(
                (e) => e.moduleInfo.type === "Hotel"
            );
            const flightDetails = this.financialDetails.bookings.find(
                (e) => e.moduleInfo.type === "Flight"
            );
            if (flightDetails) {
                const flightObject = {
                    service:
                        flightDetails.moduleInfo.routes.length === 2
                            ? "Roundtrip Flight"
                            : "Oneway Flight",
                    providerCost: flightDetails.moduleInfo.base_fare,
                    retailPrice: flightDetails.moduleInfo.flight_price,
                    markup: (
                        Number(flightDetails.moduleInfo.flight_price) -
                        Number(flightDetails.moduleInfo.base_fare)
                    ).toFixed(2),
                };
                this.items.push(flightObject);
            }
            const { providerCost, sellingPrice } = this.bookingType !== 2 ? hotelDetails : flightDetails;
            const firstObject = {
                service: hotelDetails?.moduleInfo?.hotel_name,
                providerCost: Number(providerCost).toFixed(2),
                retailPrice: sellingPrice,
                markup:
                    ((Number(
                        `${Math.floor(
                            `${hotelDetails?.moduleInfo?.rooms?.[0]?.markup}`
                        )}`
                    ) *
                        providerCost) /
                    100).toFixed(2),
            };

            if (this.financialDetails.extras) {
                this.financialDetails.extras.forEach((e) => {
                    if (e.type === "Promocode") {
                        this.secondObject = {
                            service: e.code,
                            retailPrice: e.amount,
                        };
                    }
                });
            }
            const insurances = this.financialDetails.extras?.filter(
                (e) => e.type === "Insurance"
            );
            const insuranceAmount = insurances?.reduce(
                (amount, e) => amount + Number(e.amount),
                0
            );
            const thirdObject = {
                service: "Insurance",
                retailPrice: insuranceAmount || 0,
            };

            if(this.bookingType !== 2) {
                if (Object.keys(this.secondObject).length) {
                    this.items.push(firstObject, this.secondObject, thirdObject);
                } else {
                    this.items.push(firstObject, thirdObject);
                }
            } else {
                if (Object.keys(this.secondObject).length) {
                    this.items.push(this.secondObject, thirdObject);
                } else {
                    this.items.push(thirdObject);
                }
            }
            if(this.bookingType != 2) {
                this.calculateTransferAmount();
            }
			this.calculatePriorityAmount();
			this.calculateBaggageAmount();
            const totalAmount = this.items.reduce(
                (amount, e) => amount + Number(e.retailPrice ? e.retailPrice : 0),
                0
            );
            const fourthObject = {
                service: "Total",
                retailPrice: totalAmount,
            };
            this.items.push(fourthObject);
        },

        calculateTransferAmount() {
            if (this.transferDetails.length) {
                const transferAmount = this.transferDetails.reduce(
                    (amount, e) => {
                        amount = amount + +e.amount;
                        return amount;
                    },
                    0
                );
                console.log("Transfer Amount", transferAmount);
                const fifthObject = {
                    service: "Transfer",
                    retailPrice: transferAmount,
                };
                this.items.push(fifthObject);
            }
        },

		calculatePriorityAmount() {
			if(this.priorityDetails && Object.keys(this.priorityDetails).length) {
				const priorityObject = {
					service: "Priority Boarding",
					retailPrice: this.priorityDetails.price
				}
				this.items.push(priorityObject)
			}
		},

		calculateBaggageAmount() {
			if(this.baggageDetails && Object.keys(this.baggageDetails).length) {
				const baggageObject = {
					service: "Extra Baggage",
					retailPrice: this.baggageDetails.price
				}
				this.items.push(baggageObject)
			}
		}
    },
};
</script>
