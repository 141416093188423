<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="first name"
                rules="required|min:3|max:50"
              >
                <b-form-group
                  label="First Name"
                  label-for="h-first-name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-first-name"
                    v-model="first_name"
                    :readonly="readProperty"
                    placeholder="First Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="last name"
                rules="required|min:3|max:50"
              >
                <b-form-group
                  label="Last Name"
                  label-for="h-last-name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-last-name"
                    v-model="last_name"
                    :readonly="readProperty"
                    placeholder="Last Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="last name"
                rules="min:2|max:100"
              >
                <b-form-group
                  label="Company Name"
                  label-for="h-first-name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-company-name"
                    v-model="company_name"
                    :readonly="readProperty"
                    placeholder="Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <b-form-group label="Email" label-for="h-email" label-cols-md="4">
                <b-form-input
                  id="h-email"
                  v-model="email"
                  :readonly="true"
                  type="email"
                  placeholder="Email"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Telephone"
                label-for="h-number"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-number"
                  v-model="telephone"
                  :readonly="true"
                  type="number"
                  placeholder="Telephone"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="billing name"
                rules="min:2|max:100"
              >
                <b-form-group
                  label="Billing Name"
                  label-for="h-billing"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-billing"
                    v-model="billing_name"
                    :readonly="readProperty"
                    placeholder="Billing Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="address-1"
                rules="required|max:200"
              >
                <b-form-group
                  label="Address-1"
                  label-for="h-address"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="address1"
                    :readonly="readProperty"
                    type="text"
                    placeholder="Address-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="address-2"
                rules="max:200"
              >
                <b-form-group
                  label="Address-2"
                  label-for="h-address"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-address2"
                    v-model="address2"
                    :readonly="readProperty"
                    type="text"
                    placeholder="Address-2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="country"
                rules="required"
              >
                <b-form-group
                  label="Country"
                  label-for="h-country"
                  label-cols-md="4"
                >
                  <!-- <b-form-input
                    id="h-country"
                    v-model="country"
                    :readonly="readProperty"
                    type="text"
                    placeholder="Country"
                  /> -->

                  <v-select
                    v-model="country"
                    :options="countries.list"
                    class="title"
                    placeholder="Select Country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :disabled="readProperty"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="country"
                rules="required|max:60"
              >
                <b-form-group label="City" label-for="h-city" label-cols-md="4">
                  <b-form-input
                    id="h-city"
                    v-model="city"
                    :readonly="readProperty"
                    type="text"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="zip code"
                rules="required|min:2|max:10"
              >
                <b-form-group
                  label="Zipcode"
                  label-for="h-zipcode"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-zipcode"
                    v-model="postal_code"
                    :readonly="readProperty"
                    type="text"
                    placeholder="ZipCode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="zip code"
                rules="max:15"
              >
                <b-form-group
                  label="VAT Number"
                  label-for="h-vat"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-vat"
                    v-model="vat_number"
                    :readonly="readProperty"
                    type="text"
                    placeholder="VAT Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <div class="mt-2" v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit  && wl_code === 1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="editContactDetails"
            >
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              v-if="readProperty"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="mr-1"
              @click="enableReadMode"
            >
              Edit
            </b-button>

            <b-button
              v-if="!readProperty"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="mr-1"
              @click="resetForm"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import editBookingService from "@/services/booking/editBooking.service";
import { resConditionCheck, RoleEnum, } from "@/helpers";
import countries from "@/helpers/countries";
import errorResponseHandler from "@/services/errorHandler";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    contactDetails: {
      type: Object,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      required: true,
    },
    passengerDetails: {
      type: Array,
      required: true,
    },
    bookingType: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      first_name: this.contactDetails.first_name || "",
      last_name: this.contactDetails.last_name || "",
      company_name: this.contactDetails.company_name || "",
      email: this.contactDetails.email || "",
      city: this.contactDetails.city || "",
      country:
        {
          label: this.contactDetails.country,
          value: this.contactDetails.country_code
        } || "",
      billing_name: this.contactDetails.billing_name || "",
      address1: this.contactDetails.address1 || "",
      address2: this.contactDetails.address2 || "",
      postal_code: this.contactDetails.postal_code || "",
      telephone: "",
      vat_number: this.contactDetails.vat_number || "",
      readProperty: true,
      countries,
      loading: false,
      errorResponseHandler,
      RoleEnum,
      checkLoginRole,
      checkAccess: {},
      wl_code: axios.defaults.headers.wl_code,
    };
  },

  created() {
    this.getPhoneNumber();
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
  },

  methods: {
    getPhoneNumber() {
      this.telephone = this.bookingType !== 2 ? this.passengerDetails[0].find(
        (e) => e.isPrimaryTraveller === true
      ).traveller?.phone?.phone_number : this.passengerDetails.find(
        (e) => e.isPrimaryTraveller === true
      ).traveller?.phone?.phone_number;
    },

    enableReadMode() {
      this.readProperty = false;
    },

    resetForm() {
      this.first_name = this.contactDetails.first_name || "",
      this.last_name = this.contactDetails.last_name || "",
      this.company_name = this.contactDetails.company_name || "",
      this.email = this.contactDetails.email || "",
      this.city = this.contactDetails.city || "",
      this.country =
        {
          label: this.contactDetails.country,
          value: this.contactDetails.country_code
        } || "",
      this.billing_name = this.contactDetails.billing_name || "",
      this.address1 = this.contactDetails.address1 || "",
      this.address2 = this.contactDetails.address2 || "",
      this.postal_code = this.contactDetails.postal_code || "",
      this.vat_number =  this.contactDetails.vat_number || "",
      this.readProperty = true
    },

    async editContactDetails() {
      const valid = await this.$refs.simpleRules.validate();
      const payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        billing_name: this.billing_name,
        zip_code: this.postal_code,
        city: this.city,
        country: this.country.label,
        country_code: this.country.value,
        address1: this.address1,
        address2: this.address2,
        company_name: this.company_name,
        vat_number: this.vat_number,
        email: this.email,
      };
      if (valid) {
        this.loading = true;
        try {
          const res = await editBookingService.editContactDetails(
            this.$route.params.id,
            payload,
            this.$route.query?.wl_code
          );
          if (resConditionCheck(res.status) && res.data.message) {
            this.readProperty = true;
            setTimeout(() => {
              this.loading = false;
            }, 3000);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        } catch (error) {
          setTimeout(() => {
            this.loading = false;
          }, 3000);

          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },
};
</script>
