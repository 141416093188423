<template>
  <div>
    <b-table
      ref="logTable"
      :items="items"
      :fields="tableColumns"
      striped
      responsive
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.item.show"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
          @click.native="expand(row.item.previousValue, row.item.currentValue, row)"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{
            row.item.show && currentSelected === row.index ? "Hide" : "Show"
          }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card v-show="row.item.show">

          <b-row class="mb-2">
            <!-- <b-table
              ref="refUserListTable"
              class="position-relative"
              responsive
              :fields="fields"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <template #row-details="row">
                {{ row.item.previous_value }}
              </template>

              <template #cell(new_value)="data">
                {{ data.item.currentValue }}
              </template>
            </b-table> -->
            <b-col md="12">
              <!-- <div v-for="(e, index) in row.item.previousValue" :key="index">
                {{ e}}

                </div> -->
              <b-table
                :items="newItems"
                :fields="fields"
                show-empty
                empty-text="No change in previous and current value"
                striped
                responsive
              >
                <template #cell(module)="data">
                  {{ data.item.field | UpperCase }}
                </template>

                <template #cell(previous_value)="data">
                  {{ data.item.field !== 'BookingStatus' ? data.item.previousValue : BookingStatus(data.item.previousValue) }}
                </template>
                <template #cell(new_value)="data">
                  {{ data.item.field !== 'BookingStatus' ? data.item.currentValue : BookingStatus(data.item.currentValue) }}
                </template>
              </b-table>
            </b-col>
            <!-- <b-col md="6">
              <b-table
                :items="row.item.currentValue"
                :fields="nextField"
                striped
                responsive
              >
                <template #cell(new_value)="data">
                  {{ data.item }}
                </template>
              </b-table>
            </b-col> -->
          </b-row>
        </b-card>
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(date_and_time)="data">
        {{ moment(data.item.createdDate).format("YYYY-MM-DD") }} {{ moment.utc(data.item.createdDate).format("hh:mm:ss a")}}
      </template>

    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BRow,
  BCard,
  BButton,
  BCol
} from "bootstrap-vue";
import { BookingStatus } from "@/helpers/constant";

export default {
  components: {
    BTable,
    BFormCheckbox,
    BRow,
    BCard,
    BButton,
    BCol
  },

  props: {
    logsDetails: { type: Array, required: true },
    isLoaded: { type: Boolean, required: true }
  },

  data() {
    return {
      items: this.logsDetails,
      newItems: [],
      secondObject: {},
      tableColumns: [{ key: "show_details" }, { key: "message" }, { key: "date_and_time" }],
      fields: [
        { key: "module" },
        { key: "previous_value" },
        { key: "new_value" }
      ],
      BookingStatus,
      currentSelected: '',
    };
  },

  created() {
    console.log("logs details", this.logsDetails);
  },

  methods: {
    expand(previousValue, currentValue, row) {
      this.currentSelected = row.index
      this.logsDetails.forEach((e,i) => {
        if (i !== row.index) {
          this.$set(this.logsDetails[i], "show", false);
        } else {
          this.$set(this.logsDetails[row.index], "show", true);
          this.logsDetails[row.index]._showDetails = true
        }
      })
      this.newItems = [];
      console.log("hello", previousValue, currentValue);

      Object.keys(currentValue).forEach(key => {
        if (
          (currentValue[key] || previousValue[key])
          && currentValue[key] !== previousValue[key]
        ) {
          this.newItems.push({
            field: key,
            previousValue:
              previousValue[key] || previousValue[key] === 0
                ? previousValue[key]
                : "-",
            currentValue:
              currentValue[key] || currentValue[key] === 0
                ? currentValue[key]
                : "N/A"
          });
          this.newItems.forEach(e => {
            if (
              typeof e.previousValue === "object"
              && typeof e.currentValue === "object"
            ) {
              Object.keys(e.currentValue).forEach(key => {
                if (
                  (e.currentValue[key] || e.previousValue[key])
                  && e.currentValue[key] !== e.previousValue[key]
                ) {
                  this.newItems.push({
                    field: key,
                    previousValue:
                      e.previousValue[key] || e.previousValue[key] === 0
                        ? e.previousValue[key]
                        : "N/A",
                    currentValue:
                      e.currentValue[key] || e.currentValue[key] === 0
                        ? e.currentValue[key]
                        : "N/A"
                  });
                }
              });
              this.newItems.shift();
            }
          });
        }
      });
      console.log("new items", this.newItems);
    },
    toggleDetail(row) {
      if (row.index === this.currentSelected) {
        row.toggleDetails
      }
    }
  }
};
</script>
