import axios from "axios";

function editPassengerDetails(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post(`booking/update-traveller/${id}`, data);
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function changeModuleWiseBookingDetails(data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post(`booking/change-booking-module-details`, data);
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function updateInsurance(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.put(`booking/update-booking-insurance/${id}`, data);
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function editInstallmentData(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post(`booking/change-installment-data/${id}`, data);
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function editFlightBooking(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post(`booking/edit-flight-booking/${id}`, data);
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function getCountries() {
  return axios.get(`country`);
}

function editContactDetails(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.put(`booking/edit-contact-details/${id}`, data)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function editFlightRoutes(data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.put(`booking/edit-flight-routes`, data)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function getOriginListing(wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.get(`flight/autocomplete`)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function getAirlines(wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.get(`airline`)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function addComments(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post(`booking/add-remarks/${id}`, data)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function editBaggage(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post( `booking/edit-extra-baggage/${id}`, data)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function editPriorityBoarding(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.put(`booking/edit-priority-boarding/${id}`, data)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function updateTransfer(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.put(`booking/update-transfer/${id}`, data)
  axios.defaults.headers.wl_code = wlCode
	return response;
}

function editPaymentAmountData(id, data, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post(`booking/change-payment-data/${id}`, data);
  axios.defaults.headers.wl_code = wlCode
	return response;
}


export default {
  editPassengerDetails,
  changeModuleWiseBookingDetails,
  updateInsurance,
  editInstallmentData,
  editFlightBooking,
  getCountries,
  editContactDetails,
  getOriginListing,
  getAirlines,
  editFlightRoutes,
  addComments,
  editBaggage,
  editPriorityBoarding,
  updateTransfer,
  editPaymentAmountData
};
