<template>
    <div>
         <div
            v-if="!priorityDetails || !Object.keys(priorityDetails).length"
            class="m-auto text-center"
        >
            <b-card>
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span
                            ><strong
                                >Priority booking not available for this
                                booking!</strong
                            >
                        </span>
                    </div>
                </b-alert>
            </b-card>
        </div>
        <b-card class="mb-2" v-if="priorityDetails && Object.keys(priorityDetails).length">
            <b-col md="12">
                <!-- <b-alert variant="primary" show="">
                <h3 class="mb-2">Priority Boarding</h3>
                </b-alert> -->
                <div class="mb-2 d-flex" style="justify-content:space-between">
                    <h5>
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <b>Priority Boarding</b>
                            </div>
                        </b-alert>
                    </h5>
                    <feather-icon
                        v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1"
                        style="cursor: pointer"
                        icon="EditIcon"
                        size="21"
                        @click="enableReadOnly()"
                    />
                </div>
            </b-col>
            <div>
                <validation-observer ref="simpleRules">
                    <b-form @submit.prevent>
                        <b-row>
                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="status"
                                    rules="required"
                                >
                                    <b-form-group label="Status">
                                        <v-select
                                            :disabled="readOnly"
                                            v-model="
                                                priorityBoardingForm.priority_boarding_status
                                            "
                                            :options="PriorityBoardingEnum"
                                            class="title"
                                            placeholder="Select Status"
                                            :dir="
                                                $store.state.appConfig.isRTL
                                                    ? 'rtl'
                                                    : 'ltr'
                                            "
                                            :reduce="(val) => val.value"
                                            :clearable="false"
                                        />
                                        <small class="text-danger">{{
                                            errors[0]
                                        }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="price"
                                    rules="required|max:10"
                                >
                                    <b-form-group label="Amount">
                                        <b-form-input
                                            :readonly="readOnly"
                                            v-model="
                                                priorityBoardingForm.amount
                                            "
                                            placeholder="Amount"
                                        />
                                        <small class="text-danger">{{
                                            errors[0]
                                        }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col md="6">
                                <validation-provider
                                    #default="{ errors }"
                                    name="supplier code"
                                    rules="required|min:2|"
                                >
                                    <b-form-group label="Supplier Code">
                                        <b-form-input
                                            :readonly="readOnly"
                                            v-model="
                                                priorityBoardingForm.supplier_id
                                            "
                                            placeholder="Supplier Code"
                                        />
                                        <small class="text-danger">{{
                                            errors[0]
                                        }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-button
                            v-if="(checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1"
                            type="submit"
                            variant="primary"
                            class="mr-1 mt-2"
                            :disabled="loading"
                            @click="editPriorityBoarding"
                        >
                            Submit
                            <div
                                v-if="loading"
                                class="spinner-border spinner-border-sm"
                            />
                        </b-button>
                    </b-form>
                </validation-observer>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCol,
    BRow,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
} from "bootstrap-vue";
import { PriorityBoardingEnum, resConditionCheck, RoleEnum } from "@/helpers/constant";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { BAlert } from "bootstrap-vue";
import editBookingService from "@/services/booking/editBooking.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';


export default {
    components: {
        BCol,
        BRow,
        BCard,
        BForm,
        BForm,
        BFormInput,
        BFormGroup,
        BButton,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        BButton,
        BAlert,
        ToastificationContent
    },

    data() {
        return {
            PriorityBoardingEnum,
            loading: false,
            readOnly: true,
            priorityBoardingForm: {
                priority_boarding_status: this.priorityDetails?.status || "",
                supplier_id: this.priorityDetails?.supplier_code || "",
                amount: this.priorityDetails?.price || "",
            },
            checkLoginRole,
            checkAccess: {},
            RoleEnum,
            wl_code: axios.defaults.headers.wl_code,
        };
    },

    props: {
        priorityDetails: {
            type: Object,
            required: false,
        },
    },

    created() {
        console.log("PRIORITY", this.priorityDetails);
    },

    mounted() {
        this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    },

    methods: {
        enableReadOnly() {
            this.readOnly = false;
        },

        async editPriorityBoarding() {
            try {
                const valid = await this.$refs.simpleRules.validate();
                if (valid) {
                    this.loading = true;
                    const res = await editBookingService.editPriorityBoarding(
                        this.$route.params.id,
                        this.priorityBoardingForm,
                        this.$route.query?.wl_code
                    );
                    if (resConditionCheck(res.status) && res.data.data) {
                        setTimeout(() => {
                            this.loading = false;
                        }, 3000);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: "CheckIcon",
                                variant: "success",
                            },
                        });
                        this.readOnly = true
                    }
                }
            } catch (error) {
                setTimeout(() => {
                    this.loading = false;
                }, 3000);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },
    },
};
</script>
