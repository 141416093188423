<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="paymentModal"
      v-model="showModal"
      title="Edit Payment Details"
      ok-title="Submit"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-secondary"
      centered
      @ok="handleOk"
      @cancel="handleClose"
      @close="handleClose"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required|max:40"
                name="transaction id"
              >
                <b-form-group label="Transaction ID">
                  <b-form-input
                    v-model="paymentEditForm.transaction_id"
                    name="transaction id"
                    placeholder="41e3aeaa-deb3-4d6e-a9b1-90fab96209a6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-for="mc-status"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="paymentEditForm.status"
                    :options="PaymentStatusEnum"
                    class="title"
                    placeholder="Select Status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="payment date"
                rules="required"
              >
                <b-form-group label="Payment date">
                  <!-- <flat-pickr
                    v-model="paymentEditForm.payment_date"
                    class="form-control"
                    :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
                  /> -->
                  <date-picker
                    v-model="paymentEditForm.payment_date"
                    style="width: 100%"
                    type="date"
                    format="YYYY-MM-DD"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BForm,
  VBModal,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import VueTimepicker from "vue2-timepicker";
import { required } from "@/@core/utils/validations/validations";
import {
  PaymentStatusEnum,
  resConditionCheck,
  PaymentStatus,
} from "@/helpers/constant";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    VBModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    vSelect,
    flatPickr,
    VueTimepicker,
  },

  props: {
    togglePaymentModal: {
      type: Boolean,
      default: false,
    },

    paymentObject: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      required,
      PaymentStatusEnum,
      showModal: this.togglePaymentModal,
      paymentEditForm: {
        transaction_id: this.paymentObject.paymentTransactionId || "",
        payment_date: moment(this.paymentObject.paymentDate).toDate() || "",
        status: this.paymentObject.paymentStatus,
        amount: this.paymentObject.amount,
        id: this.paymentObject.id,
      },
    };
  },

  created() {
    // this.getOriginListing();
    // this.getAirlines();
  },

  methods: {
    handleOk() {
      this.$emit("on-submit", this.paymentEditForm);
    },
    handleClose() {
      this.$emit("on-cancel");
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then((success) => {
        valid = !!success;
      });
      return valid;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
