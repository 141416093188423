var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.insuranceArray.length || !_vm.insuranceDetails)?_c('b-card',{staticClass:"m-auto text-center"},[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("Insurance not available for this booking!")])])])])],1):_vm._e(),_c('b-row',_vm._l((_vm.insuranceArray),function(insurance,index){return _c('b-col',{key:index,attrs:{"md":"6"}},[_c('b-card',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('h5',[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('b',[_vm._v(_vm._s(insurance.name))])])])],1),((_vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin && _vm.wl_code === 1) ? true : _vm.checkAccess.Edit && _vm.wl_code === 1)?_c('feather-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"EditIcon","size":"21"},on:{"click":function($event){return _vm.enableReadOnly(index)}}}):_vm._e()],1),_c('validation-observer',{ref:"simpleRules",refInFor:true},[_c('b-form',{class:("index" + index),on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status","label-for":"h-status","label-cols-md":"4"}},[_c('v-select',{staticClass:"title",attrs:{"options":_vm.InsuranceStatus,"placeholder":"Select Title","dir":_vm.$store.state.appConfig.isRTL
                                            ? 'rtl'
                                            : 'ltr',"reduce":function (val) { return val.value; },"clearable":false,"disabled":index === 0 ? _vm.readOnly : _vm.readOnly1},model:{value:(insurance.status),callback:function ($$v) {_vm.$set(insurance, "status", $$v)},expression:"insurance.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"supplier code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Supplier","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"readonly":index === 0 ? _vm.readOnly : _vm.readOnly1,"placeholder":"Supplier"},model:{value:(insurance.supplierCode),callback:function ($$v) {_vm.$set(insurance, "supplierCode", $$v)},expression:"insurance.supplierCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"insurance name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"readonly":index === 0 ? _vm.readOnly : _vm.readOnly1,"placeholder":"Type"},model:{value:(insurance.name),callback:function ($$v) {_vm.$set(insurance, "name", $$v)},expression:"insurance.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Date","label-cols-md":"4"}},[_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"YYYY.MM.DD","placeholder":"Select date","disabled":index === 0 ? _vm.readOnly : _vm.readOnly1},model:{value:(insurance.startDate),callback:function ($$v) {_vm.$set(insurance, "startDate", $$v)},expression:"insurance.startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"end date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Date","label-cols-md":"4"}},[_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"YYYY.MM.DD","placeholder":"Select date","disabled":true},model:{value:(insurance.endDate),callback:function ($$v) {_vm.$set(insurance, "endDate", $$v)},expression:"insurance.endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Days","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"value":_vm.getDateDiff(
                                        insurance.startDate,
                                        insurance.endDate
                                    ),"readonly":true,"placeholder":"Days"}})],1),_c('validation-provider',{attrs:{"name":"policy code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Policy Code","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"readonly":index === 0 ? _vm.readOnly : _vm.readOnly1,"placeholder":"Policy code"},model:{value:(insurance.policy_code),callback:function ($$v) {_vm.$set(insurance, "policy_code", $$v)},expression:"insurance.policy_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"readonly":index === 0 ? _vm.readOnly : _vm.readOnly1,"placeholder":"Amount"},model:{value:(insurance.amount),callback:function ($$v) {_vm.$set(insurance, "amount", $$v)},expression:"insurance.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),((_vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin && _vm.wl_code === 1) ? true : _vm.checkAccess.Edit && _vm.wl_code === 1)?_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"submit","variant":"primary","disabled":(index === 0 ? _vm.loading : _vm.loading1) ||
                                        (index === 0 ? _vm.readOnly : _vm.readOnly1)},on:{"click":function($event){return _vm.updateInsurance(insurance, index)}}},[_vm._v(" Submit "),(index === 0 ? _vm.loading : _vm.loading1)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()])],1):_vm._e()],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }