<template>
    <div>
        <passenger-modal
            v-if="toggleModal"
            ref="passengerModal"
            :passenger-object="passengerObject"
            :flight-details="flightDetails"
            :toggle-modal="toggleModal"
            @on-cancel="onCancel"
            @on-submit="onSubmit"
        />

        <baggage-modal
            v-if="toggleBaggageModal"
            ref="bagggeModal"
            :toggleBaggageModal="toggleBaggageModal"
            :baggageDetails="baggageDetails"
            @on-cancel="onModalCancel"
            @on-submit="onModalSubmit"
        />

        <div v-if="baggageDetails && Object.keys(baggageDetails).length && ((checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1)" style="position: absolute;right:50px">
            <feather-icon
                v-if="checkLoginRole() === RoleEnum.SuperAdmin ? true : checkAccess.Edit"
                icon="EditIcon"
                size="21"
                style="cursor: pointer"
                @click="openBaggageModal"
            />
        </div>

        <div
            v-for="(passenger, index) in passengerCounts"
            :key="index"
            class="mt-3"
        >
            <h5 v-show="bookingType !== 2">Room {{ index + 1 }}</h5>
            <b-table
                ref="refUserListTable"
                :items="bookingType !== 2 ? passengerDetails[index] : passengerDetails"
                class="position-relative"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
            >
                <template #cell(title)="data">
                    {{ data.item.traveller.prefix }}
                </template>

                <template #cell(first_name)="data">
                    {{ data.item.traveller.first_name }}
                </template>

                <template #cell(last_name)="data">
                    {{ data.item.traveller.last_name }}
                </template>

                <template #cell(dob)="data">
                    <div style="width: 110px">
                        {{ data.item.traveller.birthdate }}
                    </div>
                </template>

                <template #cell(document_number)="data">
                    {{
                        data.item.traveller.passport_number
                            ? data.item.traveller.passport_number
                            : "-"
                    }}
                </template>

                <template #cell(document_expiry)="data">
                    <div style="width: 110px">
                        {{
                            data.item.traveller.expiry_date && data.item.traveller.expiry_date !== '1900-01-01'
                                ? data.item.traveller.expiry_date 
                                : "-"
                        }}
                    </div>
                </template>

                <template #cell(adult_or_child)="data">
                    {{
                        data.item.traveller.passanger_code
                            ? (data.item.traveller.passanger_code == "ADT"
                                  ? "Adult"
                                  : data.item.traveller.passanger_code == "CHD" ? "Child" : "Infant")
                            : "-"
                    }}
                </template>

                <template #cell(20_kg_baggage)="data">
                    <b-badge :variant="extrasInitialVariant(baggageDetails ? baggageDetails.status: '')" pill>
                    {{ data.item.hasBaggage ? baggageDetails.status : "-" }}
                    </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item @click="openModal(data.item)" :disabled="!((checkLoginRole() === RoleEnum.SuperAdmin && wl_code === 1) ? true : checkAccess.Edit && wl_code === 1)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { BTable, BDropdownItem, BDropdown, BBadge } from "bootstrap-vue";
import PassengerModal from "@/views/modal/PassengerEditModal.vue";
import BaggageModal from "@/views/modal/BaggageStatusEditModal.vue";
import moment from "moment";
import editBookingService from "@/services/booking/editBooking.service";
import errorResponseHandler from "@/services/errorHandler";
import { resConditionCheck, RoleEnum, } from "@/helpers";
import { extrasInitialVariant } from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import axios from 'axios';

export default {
    components: {
        BTable,
        BDropdownItem,
        BDropdown,
        PassengerModal,
        BaggageModal,
        BBadge
    },

    props: {
        passengerDetails: {
            type: Array,
            required: true,
        },
        flightDetails: {
            required: true,
        },
        baggageDetails: {
            type: Object,
            required: false,
        },
        isLoaded: {
            type: Boolean,
            required: true,
        },
        bookingType: {
            type: Number,
            required: true
        },
    },

    data() {
        return {
            items: [],
            toggleModal: false,
            toggleBaggageModal: false,
            isModalLoaded: 0,
            passengerObject: {},
            extrasInitialVariant,
            tableColumns: [
                { key: "title" },
                { key: "first_name" },
                { key: "last_name" },
                { key: "dob" },
                { key: "document_number" },
                { key: "document_expiry" },
                { key: "adult_or_child" },
                { key: "20_kg_baggage" },
                { key: "actions" },
            ],
            RoleEnum,
            checkLoginRole,
            checkAccess: {},
            wl_code: axios.defaults.headers.wl_code,
        };
    },

	mounted() {
		this.checkAccess = accessRightCheck(this.$route.meta.module.name);
	},
    created() {
        console.log("passenger details", this.baggageDetails);
    },
    computed: {
        passengerCounts() {
            let c = [];
            for(var i=0; i < this.passengerDetails.length; i++) {
                c[i] = i;
                console.log('wqewqeqw')
            }
            return this.bookingType !== 2 ? this.passengerDetails : [0];
        }
    },

    mounted() {
		this.checkAccess = accessRightCheck(this.$route.meta.module.name);
	},

    methods: {
        async openModal(data) {
            this.toggleModal = true;
            this.passengerObject = data;
        },

        onCancel() {
            this.toggleModal = false;
        },

        onModalCancel() {
            this.toggleBaggageModal = false;
        },

        openBaggageModal() {
            this.toggleBaggageModal = true;
            console.log("status", this.toggleBaggageModal);
        },

        async onModalSubmit(baggageStatus) {
            this.toggleBaggageModal = false;
            try {
                const res = await editBookingService.editBaggage(
                    this.$route.params.id,
                    { baggage_status: baggageStatus },
                    this.$route.query?.wl_code
                );
                if (resConditionCheck(res.status) && res.data.message) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.data.message,
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                    this.$emit("callBookingDetailbyId");
                }
            } catch (error) {
                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },

        async onSubmit(passengerForm, expiry_date) {
            console.log("expiry_dateee", expiry_date);
            const valid = await this.$refs.passengerModal.validationForm();
            this.toggleModal = false;
            const travellerDetails = {
                ...this.passengerObject.traveller,
                prefix: passengerForm.prefix,
                first_name: passengerForm.first_name,
                last_name: passengerForm.last_name,
                passport_number: passengerForm.passport_number,
                expiry_date: expiry_date
                    ? moment(expiry_date).format("YYYY-MM-DD")
                    : "",
                birthdate: moment(passengerForm.birthdate).format("YYYY-MM-DD"),
                passanger_code:
                    this.passengerObject.traveller.passanger_code /*=== "Adult"
                        ? "ADT"
                        : "CHD"*/,
            };

            if (valid) {
                try {
                    const res = await editBookingService.editPassengerDetails(
                        this.passengerObject.id,
                        {
                            traveller: btoa(
                                unescape(
                                    encodeURIComponent(
                                        JSON.stringify(travellerDetails)
                                    )
                                )
                            ),
                        },
                        this.$route.query?.wl_code
                    );
                    if (resConditionCheck(res.status) && res.data.data) {
                        this.$emit("callBookingDetailbyId");
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: "CheckIcon",
                                variant: "success",
                            },
                        });
                    }
                } catch (error) {
                    const errorData = errorResponseHandler(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorData,
                            icon: "X-CircleIcon",
                            variant: "danger",
                        },
                    });
                }
            }
        },
    },
};
</script>
