import axios from 'axios';

function getAll(payload) {
  return axios.get('customer/list', {
    params: payload
  })
}

function deleteCustomer(id) {
  return axios.delete(`customer/${id}`)
}

function createCustomer(userData) {
  return axios.post('customer', userData)
}

function updateCustomer(id, editData) {
  return axios.put(`customer/edit/${id}`, editData)
}

function getCustomerById(id) {
  return axios.get(`customer/${id}`)
}

function customerToAgent(id) {
  return axios.get(`customer/customer-to-agent/${id}`)
}

function signInAsAgentFrontend(id) {
  return axios.post(`customer/signin-as-agent`, id)
}
function getCustomerAndAgentListing(wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  axios.defaults.headers.wl_code = wl_code
  const response = axios.get(`customer/customer-agent/list`)
  axios.defaults.headers.wl_code = wlCode
  return response
}

function getAgentDetails(userId, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code) {
    axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.get(`customer/agent-details/${userId}`)
  axios.defaults.headers.wl_code = wlCode
  return response
}

export default {
  getAll,
  deleteCustomer,
  createCustomer,
  updateCustomer,
  getCustomerById,
  customerToAgent,
  signInAsAgentFrontend,
  getCustomerAndAgentListing,
  getAgentDetails
}
