import axios from "axios";

function getBookingListing(payload, wl_code) {
	let wlCode = axios.defaults.headers.wl_code
	wl_code = wl_code !== 'all' ? wl_code : 1
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
    const response = axios.get("booking/list-booking", { params: payload });
    axios.defaults.headers.wl_code = wlCode
    return response 
}

function addRefund(data, wl_code) {
	let wlCode = axios.defaults.headers.wl_code
	wl_code = wl_code !== 'all' ? wl_code : 1
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
	const response = axios.post("booking/add-refund", data);
	axios.defaults.headers.wl_code = wlCode
	return response
}

function getBookingDetailsById(id, wl_code) {
	let wlCode = axios.defaults.headers.wl_code
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
	const response =  axios.get(`booking/booking-details/${id}`);
	axios.defaults.headers.wl_code = wlCode
	return response
}

function changeBookingStatus(data, wl_code) {
	let wlCode = axios.defaults.headers.wl_code
	wl_code = wl_code !== 'all' ? wl_code : 1
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
	const response = axios.post(`booking/change-booking-status`, data);
	axios.defaults.headers.wl_code = wlCode
	return response
}

// Manual Bookings Services
function getHotelDestinationsList( wl_code = 1) {
	let wlCode = axios.defaults.headers.wl_code
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
	const response = axios.get("hotel");
	axios.defaults.headers.wl_code = wlCode
	return response
}

function createNewBooking(data) {
	return axios.post(`booking/manual-booking`, data);
}

function saveContactDetails(id, data) {
	return axios.put(`booking/billing-info/${id}`, data);
}

function saveHotelDetails(id, data) {
	return axios.post(`booking/update-hotel-booking/${id}`, data);
}

function getInsuranceTypes(data) {
	return axios.post(`extra/`, data);
}

function updateInsurance(id, data) {
	return axios.put(`booking/update-insurance/${id}`, data);
}

function updateComment(id, data) {
	return axios.put(`booking/update-comment/${id}`, data);
}

function getAllUsers() {
	return axios.post(`admin/list/filters-with-role`, {});
}

function getImportantInfo(moduleId, lang) {
	return axios.get(`remark/${moduleId}/${lang}`);
}

function updateIntallment(id, data) {
	return axios.put(`booking/update-installment/${id}`, data);
}

function addUpdateTraveler(id, data) {
	return axios.put(`booking/update-travler/${id}`, data);
}

function changeInstallmentStatus(id, data) {
	return axios.post(`booking/change-installment/${id}`, data);
}

function sendBookingEmail(id, data, wl_code) {
	let wlCode = axios.defaults.headers.wl_code
	if(wl_code){
		axios.defaults.headers.wl_code = wl_code
	}
	const response = axios.post(`booking/booking-details-frontend/${id}`, data);
	axios.defaults.headers.wl_code = wlCode
	return response;
}

export default {
	getBookingListing,
	addRefund,
	getBookingDetailsById,
	changeBookingStatus,
	getHotelDestinationsList,
	createNewBooking,
	saveContactDetails,
	saveHotelDetails,
	getInsuranceTypes,
	updateInsurance,
	updateComment,
	getAllUsers,
	getImportantInfo,
	updateIntallment,
	addUpdateTraveler,
	changeInstallmentStatus,
	sendBookingEmail
};
