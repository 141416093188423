var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"passengerModal",attrs:{"id":"modal-prevent-closing","title":"Edit Passenger Details","ok-title":"Submit","no-close-on-backdrop":"","no-close-on-esc":"","cancel-variant":"outline-secondary","centered":""},on:{"ok":_vm.handleOk,"cancel":_vm.handleClose,"close":_vm.handleClose},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-select-title","state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"title",attrs:{"options":_vm.TravelerPrefix,"placeholder":"Select Title","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.passengerForm.prefix),callback:function ($$v) {_vm.$set(_vm.passengerForm, "prefix", $$v)},expression:"passengerForm.prefix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First name"}},[_c('validation-provider',{attrs:{"name":"first name","rules":"required|max:60|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input"},model:{value:(_vm.passengerForm.first_name),callback:function ($$v) {_vm.$set(_vm.passengerForm, "first_name", $$v)},expression:"passengerForm.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last name"}},[_c('validation-provider',{attrs:{"name":"last name","rules":"required|max:60|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-input"},model:{value:(_vm.passengerForm.last_name),callback:function ($$v) {_vm.$set(_vm.passengerForm, "last_name", $$v)},expression:"passengerForm.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date of birth"}},[_c('validation-provider',{attrs:{"name":"date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"YYYY.MM.DD","placeholder":"Select date"},model:{value:(_vm.passengerForm.birthdate),callback:function ($$v) {_vm.$set(_vm.passengerForm, "birthdate", $$v)},expression:"passengerForm.birthdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.flightDetails)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Document number"}},[_c('validation-provider',{attrs:{"name":"document number","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document-input"},model:{value:(_vm.passengerForm.passport_number),callback:function ($$v) {_vm.$set(_vm.passengerForm, "passport_number", $$v)},expression:"passengerForm.passport_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1724346010)})],1)],1):_vm._e(),(!_vm.flightDetails)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Document number"}},[_c('validation-provider',{attrs:{"name":"document number","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document-input"},model:{value:(_vm.passengerForm.passport_number),callback:function ($$v) {_vm.$set(_vm.passengerForm, "passport_number", $$v)},expression:"passengerForm.passport_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1724346010)})],1)],1):_vm._e(),(_vm.flightDetails)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Document Expiry"}},[_c('validation-provider',{attrs:{"name":"expiry date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"YYYY.MM.DD","placeholder":"Select date"},model:{value:(_vm.expiry_date),callback:function ($$v) {_vm.expiry_date=$$v},expression:"expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2403142927)})],1)],1):_vm._e(),(!_vm.flightDetails)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Document Expiry"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"YYYY.MM.DD","placeholder":"Select date"},model:{value:(_vm.expiry_date),callback:function ($$v) {_vm.expiry_date=$$v},expression:"expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2403142927)})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }