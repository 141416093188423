<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="passengerModal"
      v-model="showModal"
      title="Edit Passenger Details"
      ok-title="Submit"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-secondary"
      centered
      @ok="handleOk"
      @cancel="handleClose"
      @close="handleClose"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="mc-select-title"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="passengerForm.prefix"
                    :options="TravelerPrefix"
                    class="title"
                    placeholder="Select Title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="First name">
                <validation-provider
                  #default="{ errors }"
                  name="first name"
                  rules="required|max:60|min:3"
                >
                  <b-form-input
                    id="name-input"
                    v-model="passengerForm.first_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Last name">
                <validation-provider
                  #default="{ errors }"
                  name="last name"
                  rules="required|max:60|min:3"
                >
                  <b-form-input
                    id="last-input"
                    v-model="passengerForm.last_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Date of birth">
                <validation-provider
                  #default="{ errors }"
                  name="date of birth"
                  rules="required"
                >
                  <date-picker
                    v-model="passengerForm.birthdate"
                    style="width:100%"
                    type="date"
                    format="YYYY.MM.DD"
                    placeholder="Select date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="flightDetails" md="6">
              <b-form-group label="Document number">
                <validation-provider
                  #default="{ errors }"
                  name="document number"
                  rules="required|max:256"
                >
                  <b-form-input
                    id="document-input"
                    v-model="passengerForm.passport_number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="!flightDetails" md="6">
              <b-form-group label="Document number">
                <validation-provider
                  #default="{ errors }"
                  name="document number"
                  rules="max:256"
                >
                  <b-form-input
                    id="document-input"
                    v-model="passengerForm.passport_number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="flightDetails" md="6">
              <b-form-group label="Document Expiry">
                <validation-provider name="expiry date" #default="{ errors }" rules="required">
                  <date-picker
                    v-model="expiry_date"
                    style="width:100%"
                    type="date"
                    format="YYYY.MM.DD"
                    placeholder="Select date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="!flightDetails" md="6">
              <b-form-group label="Document Expiry">
                <validation-provider #default="{ errors }">
                  <date-picker
                    v-model="expiry_date"
                    style="width:100%"
                    type="date"
                    format="YYYY.MM.DD"
                    placeholder="Select date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BForm,
  VBModal,
  BRow,
  BCol
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import vSelect from "vue-select";
import { required } from "@/@core/utils/validations/validations";
import { TravelerPrefix } from "@/helpers/constant";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    BRow,
    vSelect,
    BCol
  },

  directives: { "b-modal": VBModal, Ripple },

  props: {
    passengerObject: {
      type: Object,
      required: true
    },
    flightDetails: {
      required: true
    },
    toggleModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModal: this.toggleModal,
      required,
      TravelerPrefix,
      passengerForm: {
        prefix: this.passengerObject.traveller.prefix,
        first_name: this.passengerObject?.traveller?.first_name,
        last_name: this.passengerObject?.traveller?.last_name,
        birthdate: moment(
          this.passengerObject?.traveller?.birthdate,
          moment.defaultFormat
        ).toDate(),
        passport_number:
          this.passengerObject?.traveller?.passport_number || "-",
        // expiry_date: this.passengerObject?.traveller?.expiry_date
        //   ? moment(this.passengerObject?.traveller?.expiry_date).toDate()
        //   : "-" || "-"
      },

      expiry_date: this.passengerObject?.traveller?.expiry_date
        ? moment(this.passengerObject?.traveller?.expiry_date).toDate()
        : "-" || "-"
    };
  },

  created() {
    console.log("in mounted", this.flightDetails);
  },

  watch: {
    expiry_date: {
      handler(newValue) {
        if(!newValue) {
          this.expiry_date = ""
        }
      }
    }
  },

  methods: {
    handleOk() {
      this.$emit("on-submit", this.passengerForm, this.expiry_date);
    },

    handleClose() {
      this.$emit("on-cancel");
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        valid = !!success;
      });
      return valid;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
