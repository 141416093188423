<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="remark"
                rules="max:30"
              >
                <b-form-group label="Remarks">
                  <b-form-input
                    v-model="remarkForm.remarks"
                    placeholder="Remarks"
                  />
                    <!-- :readonly="!(checkLoginRole() === RoleEnum.SupportAgent ? true : checkAccess.Edit)" -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Notes">
                <b-form-textarea
                  id="textarea-default"
                  v-model="remarkForm.notes"
                  placeholder="Notes"
                  rows="3"
                />
                  <!-- :readonly="!(checkLoginRole() === RoleEnum.SupportAgent ? true : checkAccess.Edit)" -->
              </b-form-group>
            </b-col>

            <b-col >
            <!-- v-if="checkLoginRole() === RoleEnum.SupportAgent ? true : checkAccess.Edit" -->
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="loading"
                @click="addRemark"
              >
                Submit
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import editBookingService from "@/services/booking/editBooking.service";
import { resConditionCheck, RoleEnum, } from "@/helpers";
import errorResponseHandler from "@/services/errorHandler";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver
  },

  props: {
    commentDetails: { required: true },
    isLoaded: { type: Boolean, required: true }
  },

  data() {
    return {
      remarkForm: {
        remarks: this.commentDetails?.remarks || "",
        notes: this.commentDetails?.notes || ""
      },
      loading: false,
      RoleEnum,
			checkLoginRole,
			checkAccess: {},
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
  },

  methods: {
    async addRemark() {
      const valid = await this.$refs.simpleRules.validate();
      if (valid) {
        this.loading = true;
        try {
          const payload = btoa(
            unescape(encodeURIComponent(JSON.stringify(this.remarkForm)))
          );

          const res = await editBookingService.addComments(
            this.$route.params.id,
            { remarks: payload },
            this.$route.query?.wl_code
          );
          if (resConditionCheck(res.status) && res.data.data) {
            setTimeout(() => {
              this.loading = false;
            }, 3000);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success"
              }
            });
          }
        } catch (error) {
          setTimeout(() => {
            this.loading = false;
          }, 3000);
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: { title: errorData, icon: "X-CircleIcon", variant: "danger" }
          });
        }
      }
    }
  }
};
</script>
